import React, { useEffect } from 'react';
import { Row, Col, Container } from "react-bootstrap";
import { Link } from 'react-router-dom';
import '../App.css';
import { useLocation } from 'react-router-dom';

function UseTerms() {

    const { pathname } = useLocation();

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[pathname]);

  return (
        <div className='mt-5'>
            <Container >
                <Row className='pt-4'>
                    <Col>
                        <h2>Condiciones de uso de nuestros productos y servicios</h2>
                        <p><b>IMPORTANTE--POR FAVOR LEA ATENTAMENTE</b>, estas Condiciones son un acuerdo entre usted y 
                        Grupo Valor International Solutions SAS, una empresa de Bogota. Colombia. Las referencias 
                        a «<b>nos</b>», «<b>nosotros</b>», «<b>nuestro</b>» y «<b>GVIS</b>» significarán Grupo Valor 
                        International Solution SAS.</p>
                        <p>Estas Condiciones rigen su uso y acceso al software, los productos y/o servicios de GVIS 
                            (de manera individual o en conjunto, los «<b>Productos</b>») y cualquier información, contenido, 
                            texto, gráficos, fotos u otros materiales cargados, descargados, adquiridos o que aparecen en 
                            los Productos o a través de ellos (a los que en conjunto se hace referencia como «<b>Contenido</b>»). 
                            Pueden aplicar condiciones o requisitos del producto adicionales a nuestros Productos individuales 
                            y se encuentran disponibles con el Producto pertinente. Estas Condiciones aplican a todas las 
                            visitas, usuarios y otros que usen y accedan a los Productos («<b>Usuarios</b>»).</p>
                        <p>Al hacer uso de los Productos o acceder a ellos acepta el carácter vinculante de estas Condiciones.</p>
                        <p>SÍRVASE LEER ATENTAMENTE LAS PRESENTES CONDICIONES PARA ASEGURARSE DE QUE COMPRENDE CADA DISPOSICIÓN. 
                            LAS PRESENTES CONDICIONES CONTIENEN UNA DISPOSICIÓN DE ARBITRAJE INDIVIDUAL OBLIGATORIO Y 
                            UNA EXENCIÓN DE DEMANDA COLECTIVA/JUICIO CON JURADO QUE, EN LA MEDIDA QUE SEA APLICABLE A SU 
                            CASO, REQUIERE EL USO DE ARBITRAJE A TÍTULO PERSONAL PARA RESOLVER DISPUTAS EN LUGAR DE JUICIOS 
                            CON JURADO O DEMANDAS COLECTIVAS.</p>
                        <p>No obstante lo que antecede, nada en estas Condiciones sustituye o limita sus derechos conforme a 
                            (1) los términos y condiciones de cualquier acuerdo por escrito que se haya celebrado con GVIS respecto 
                            al uso de los Productos o (2) las leyes o regulaciones aplicables en la medida que estas Condiciones estén 
                            prohibidas por tales leyes o regulaciones. En el caso de cualquier conflicto entre estas Condiciones y los 
                            términos y condiciones de un acuerdo por escrito aplicable que se haya celebrado con GVIS, prevalecerán los 
                            términos y condiciones del acuerdo por escrito.</p>
                        <h3>1. Condiciones básicas & cuentas</h3>
                        <p>Usted es responsable del uso que le dé a los Productos. Puede usar los Productos solo si es posible 
                            concertar un contrato vinculante con GVIS. Su cuenta con GVIS (y el uso del Producto) le da acceso 
                            a los servicios y las funciones que podemos establecer y mantener ocasionalmente y a nuestra entera 
                            discreción. Podemos mantener distintos tipos de cuentas para distintos tipos de Usuarios. 
                            Si acepta tales Condiciones y usa los Productos en nombre de una empresa, organización, gobierno 
                            u otra entidad jurídica, entonces (a) «usted» lo incluye a usted y a su entidad, y (b) representa y 
                            garantiza que es un representante autorizado de la entidad con autoridad para vincular la entidad a 
                            tales Condiciones, y que acepta tales Condiciones en nombre de la entidad. Puede usar los Productos 
                            únicamente si cumple con tales Condiciones, cualquier acuerdo celebrado por su institución respecto a 
                            los Productos y todas las leyes, normas y regulaciones locales, estatales, nacionales e internacionales 
                            aplicables.</p>
                        <p>En los casos en que ha autorizado o registrado a otro individuo, lo que incluye a un menor, 
                            a usar su cuenta o cuentas, es completamente responsable de (i) la conducta en internet de dicho 
                            Usuario; (ii) controlar el uso y acceso del Usuario a los Productos y (iii) las consecuencias 
                            de cualquier uso incorrecto. GVIS requiere que los padres, tutores u otro adulto autorizado o 
                            institución educativa de un menor consientan afirmativamente a que dicho menor use un Producto de 
                            GVIS. GVIS se reserva el derecho a proveer acceso a la cuenta del menor a los padres, tutores u 
                            otro adulto autorizado o institución educativa del menor tras la solicitud de dicho adulto. Por 
                            información adicional sobre cómo usamos su información, sírvase ver nuestra 
                            <Link to="/politica-de-privacidad"> Política de privacidad</Link>.</p>
                        <p>Usted es exclusivamente responsable de sus interacciones con otros Usuarios. Nos reservamos el derecho, 
                            pero no tenemos obligación, de monitorear disputas entre usted y otros Usuarios. 
                            GVIS no será responsable de sus interacciones con otros Usuarios ni de cualquier acción u omisión 
                            del Usuario.</p>
                        <h3>2. Privacidad & uso de información personal</h3>
                        <p>Cualquier información que provea a GVIS, lo cual incluye su nombre, apellido, dirección de correo 
                            electrónico y cualquier otra información que incluya información personal que haya provisto, 
                            pueda proveer o podamos recopilar con relación a su uso de los Productos («<b>su información</b>»),
                            será recopilada, conservada y usada a efectos de proveer los Productos a usted y su institución, 
                            o de acuerdo con tales Condiciones, nuestra política de privacidad que se encuentra 
                            <Link to="/politica-de-privacidad"> aquí</Link>.
                            («<b>Política de privacidad</b>»), cualquier condición adicional aplicable a un Producto 
                            individual y cualquier acuerdo que celebre su institución con relación al Producto. 
                            Usted comprende que, mediante el uso de los Productos, da consentimiento para la recopilación y 
                            el uso (tal como se establece en estas Condiciones, la Política de privacidad y cualquier acuerdo 
                            celebrado por su institución con relación al Producto) de su información, lo que incluye la 
                            transferencia de su información a Estados Unidos y/u otros países para su almacenamiento, 
                            alojamiento (hosting), procesamiento y uso por parte de GVIS.</p>
                        <p>Para que no haya lugar a dudas, la información personal, que incluye los Datos del estudiante 
                            (definidos más adelante), provista a GVIS mediante el uso de estos Productos es regulada por 
                            las condiciones del acuerdo entre GVIS y la institución educativa pertinente respecto al uso de 
                            los Productos. Tal como sucede entre GVIS y usted, usted y/o la institución educativa poseen 
                            todo derecho, título y participación respecto a todos los Datos del estudiante que nos provea o 
                            facilite de otra manera, y nosotros no poseemos, controlamos ni otorgamos licencias respecto 
                            a tales Datos del estudiante, excepto para proveer los Productos a usted y a la institución 
                            educativa que provee los productos y controla los Datos del estudiante de los registros educativos, 
                            si corresponde, y tal como se describe en la presente. «<b>Datos del estudiante</b>» es cualquier 
                            información (en cualquier formato) que se relacione directamente con un ex-estudiante o 
                            un estudiante actual y se conserve en una escuela, distrito escolar o entidad u organización 
                            relacionada, o por nosotros, como parte de la disposición de los Productos. 
                        </p>
                        <p>Los Datos del estudiante pueden incluir «registros educativos». GVIS acuerda tratar los Datos del 
                            estudiante como confidenciales y no compartirlos con terceros distintos a los descritos en el 
                            acuerdo de GVIS con la institución educativa. Si tiene dudas respecto a la privacidad y el uso de su 
                            información, se puede poner en contacto con nosotros a través de  
                            <a href="mailto:info@gvalor.co"> info@gvalor.co </a> o con su institución educativa.</p>
                        <h3>3. Contenido & sus derechos</h3>
                        <p>Usted es responsable del Contenido que publique en los Productos y cualesquiera consecuencias que 
                            conlleve. El contenido que envíe, publique o exhiba puede ser visto por otros Usuarios de los 
                            Productos. Es posible controlar el Contenido al que otros Usuarios de determinados Productos 
                            pueden acceder mediante las opciones de privacidad en determinados Productos.</p>
                        <p>Usted mantiene sus derechos respecto a cualquier Contenido que envíe, publique o exhiba en los 
                            Productos o a través de ellos. Al enviar, publicar o exhibir Contenido en los Productos o a 
                            través de ellos, nos otorga una licencia (con derecho a sublicencia) sin regalías ni exclusividad a 
                            nivel mundial para usar, alojar (host), almacenar, copiar, reproducir, procesar, adaptar, modificar, 
                            publicar, transmitir, crear trabajos derivados de este, comunicar, exhibir y/o distribuir dicho 
                            Contenido en cualquiera y todos los medios o métodos de distribución (actualmente conocidos o 
                            desarrollados más adelante) como parte de proveer cualquiera de los Productos. Acepta que esta 
                            licencia incluye el derecho de GVIS a proveer, promover y mejorar los Productos y hacer que el 
                            Contenido enviado a los Productos o a través de ellos se encuentre disponible para otras 
                            instituciones o individuos como parte de proveer los Productos (inclusive luego de finalizar su 
                            uso de los Productos) y de acuerdo con su configuración de privacidad. Usted representa y garantiza 
                            que posee todo derecho, facultad y autoridad necesarios para otorgar los derechos otorgados en 
                            la presente respecto a cualquier Contenido que envíe.</p>
                        <p>Todo Contenido, ya sea publicado públicamente o transmitido por privado es responsabilidad exclusiva 
                            de la persona que originó dicho Contenido. Es posible que el Contenido publicado mediante los 
                            Productos no sea monitoreado ni controlado y no asumimos resposabilidad alguna por dicho Contenido. 
                            Cualquier uso o confianza en cualquier Contenido o materiales publicados mediante los Productos u 
                            obtenidos por usted a través de los Productos es a su propio riesgo.</p>
                        <p>Además, en conexión con el Contenido que envía, publica o exhibe en los Productos o a través de ellos, 
                            afirma, representa y garantiza lo siguiente: (a) tiene el consentimiento por escrito de 
                            cada una de las personas naturales que se pueden identificar en el Contenido, si corresponde, 
                            para usar el nombre o similar de esta persona de la manera contemplada por cualquier Producto y 
                            estas Condiciones, y cada una de tales personas lo liberan de cualquier responsabilidad que pueda 
                            surgir con relación a dicho uso; (b) ha obtenido y es exclusivamente responsable de obtener todos 
                            los consentimientos que requiera la ley para publicar cualquier Contenido con relación a terceros; 
                            (c) su Contenido y el uso que GVIS haga de este, tal como lo contemplan estas Condiciones y cualquier 
                            Producto no infringirán ninguna ley ni infringirán ningún derecho de cualquier tercero, 
                            lo que incluye, de modo no taxativo, cualquier derecho de propiedad intelectual y derecho de 
                            privacidad, y (d) GVIS puede ejercer los derechos a su Contenido otorgados conforme a estas 
                            Condiciones sin obligación de pago de cualquier tarifa de asociación, residual, pago, tarifa o 
                            regalías que se deba pagar conforme a cualquier acuerdo de negociación colectiva, acuerdo de licencia 
                            o de otro modo.</p>
                        <p>GVIS respeta los derechos de propiedad intelectual de terceros y espera que los Usuarios de los 
                            Productos también lo hagan. Responderemos a notificaciones de supuestas infracciones de derechos 
                            de autor que cumplan con la ley aplicable y se provean de forma apropiada. Si sospecha que su 
                            Contenido ha sido copiado de manera que constituye infracción de derechos de autor, sírvase 
                            brindarnos la siguiente información: (i) una firma física o electrónica del propietario de los 
                            derechos de autor o una persona autorizada a actuar en su nombre; (ii) identificación de la obra 
                            protegida por derechos de autor que se supone fue objeto de infracción; (iii) identificación del 
                            material que se supone que infringe o es el objeto de una actividad que infringe y que se debe 
                            eliminar o el acceso al cual se debe inhabilitar e información considerablemente suficiente para 
                            hacer posible la ubicación del material; (iv) su información de contacto, lo que incluye dirección, 
                            número de teléfono y una dirección de correo electrónico; (v) una declaración suya que establece de 
                            buena fe que el uso del material de la manera en discordia no está autorizado por el propietario de 
                            los derechos de autor, su agente o la ley, y (vi) una declaración que establece que la información 
                            en la notificación es precisa y, bajo pena de perjurio, que usted está autorizado a actuar en nombre 
                            del propietario de los derechos de autor.</p>
                        <p>Nos reservamos el derecho a eliminar Contenido que se alega que infringe sin aviso previo, 
                            a nuestra entera discreción y sin responsabilidad alguna para usted. Nuestro agente de derechos 
                            de autor designado para la notificación de supuesta infracción de derechos de autor que aparece en el 
                            Producto es:
                        </p>
                            <ul>Grupo Valor International Solutions SAS.</ul>
                            <ul>A la atención del: Asesor General</ul>
                            <ul>Carrera 69 D 1 – 51 sur. Apto 619. Bogota Colombia</ul>
                            <ul>Correo electrónico: info@gvalor.co</ul>
                        <h3>4. Su licencia para usar los productos</h3>
                        <h4>A. Licencia</h4>
                        <p>GVIS le otorga una licencia que no es exclusiva ni transferible, sin regalías, a nivel mundial y 
                            a título personal para usar los Productos, sujeta a estas Condiciones y cualquier acuerdo celebrado 
                            por su institución con relación a los Productos, únicamente para el uso previsto de los Productos, 
                            y no para demostraciones, análisis o inteligencia competitiva. No puede copiar, modificar, distribuir, 
                            vender o arrendar ninguna parte de nuestros Productos ni puede aplicar ingeniería inversa o intentar 
                            extraer el código fuente de cualquier Producto, excepto si las leyes prohíben tales restricciones o 
                            usted cuenta con permiso por escrito.</p>
                        <h4>B. Uso aceptable</h4>
                        <p>Cuando se usan los Productos no debe: (i) eludir, inhabilitar o interferir de otra manera con 
                            cualesquiera características de los Productos relacionadas con la seguridad o características 
                            que previenen o restringen el uso o la copia del contenido al que se puede acceder mediante los 
                            Productos; (ii) crear más de una cuenta para su uso con un Producto particular (sin embargo, puede 
                            conectar todas sus redes sociales o cuentas de otro Producto, en cada caso que respaldemos, con la 
                            cuenta que usa con dicho Producto); (iii) brindar cualquier información falsa o engañosa o permitir 
                            que otra persona use un Producto con su nombre o en su nombre; (iv) hacerse pasar por cualquier 
                            persona, o tergiversar su identidad o afiliación con cualquier persona o dar la impresión de que 
                            están vinculados a GVIS, si este no es el caso; (v) usar un Producto si hemos suspendido o prohibido 
                            que lo use; (vi) enviar correo basura, correo no deseado, mensajes repetitivos, publicidad no 
                            solicitada o correos electrónicos, llamadas o mensajes de texto de marketing, o participar en 
                            cualquier actividad que infrinja las leyes y regulaciones contra comunicaciones no deseadas, 
                            las que incluyen, de modo no taxativo, la Ley CAN-SPAM de 2003, la Ley de protección al consumidor 
                            telefónico, u otras leyes con relación a correos electrónicos, llamadas o mensajes de texto; (vii) 
                            proponer, promover o participar en cualquier conducta ilegal o ilícita o una conducta que causa daño 
                            o perjuicio a cualquier persona o propiedad; (viii) modificar, interferir, interceptar, interrumpir 
                            o piratear cualquier Producto o recopilar cualquier dato de un Producto de forma que difiera con las 
                            Condiciones de uso; (ix) uso indebido de los Productos al introducir intencionalmente virus, 
                            Troyanos, gusanos, bombas lógicas u otro material que dañaría los Productos o cualquier equipamiento 
                            del Usuario de los Productos; (x) enviar o contribuir cualquier Contenido que contiene desnudez o 
                            violencia o es agresivo, amenazador, obsceno, engañoso, falso u ofensivo (en cada caso, salvo que 
                            dicho Contenido sea enviado o contribuido con una finalidad predominantemente educativa, tal como, 
                            por ejemplo, materiales históricos contribuidos mediante un Producto para debatir en clase); (xi) 
                            enviar o contribuir cualquier Contenido sin permiso del propietario del contenido o infringir de otra 
                            manera los derechos de autor, marca registrada, privacidad, publicidad u otros derechos de terceros; 
                            (xii) usar cualquier Contenido que infrinja cualesquiera condiciones de concesión de licencia 
                            especificadas por el propietario; (xiii) enviar o contribuir cualquier información o comentario sobre 
                            otra persona sin el permiso de esa persona; (xiv) amenazar, agredir o invadir la privacidad de otro, 
                            o causar molestias, inconvenientes o ansiedad innecesaria o tomar cualquier medida que probablemente 
                            hostigue, altere, avergüence, alarme o moleste a cualquier otra persona o (xv) usar cualquier sistema 
                            automatizado, lo que incluye, de modo no taxativo, «robots», «arañas» o «lectores sin conexión» para 
                            acceder a un Producto de manera que envíe más mensajes de solicitud al Producto de los que puede 
                            producir razonablemente un ser humano en el mismo período de tiempo.</p>
                        <p>La falta de cumplimiento de estas reglas de uso aceptable constituye una infracción grave de 
                            estas Condiciones de uso y puede resultar en la adopción de todas o cualesquiera de las siguientes 
                            medidas (con o sin aviso): (a) retiro inmediato, temporal o permanente de su derecho a usar 
                            nuestros Productos; (b) remoción inmediata, temporal o permanente de cualquier Contenido; 
                            (c) emisión de una advertencia para usted; (d) acción legal contra usted, la que incluye procesos 
                            judiciales para el reembolso de todos los costos (los que incluyen, de modo no taxativo, 
                            costos legales y administrativos razonables) que surgen del incumplimiento y (e) divulgación 
                            de dicha información a autoridades del orden público según se estime razonablemente necesario.
                            Las respuestas descritas en esta Sección son no taxativas y podemos tomar cualquier otra medida 
                            que consideremos apropiada de manera razonable.</p>
                        <h4>C. Cambios a los productos</h4>
                        <p>Podemos, sin previo aviso, cambiar cualquier Producto; dejar de proveer cualquier Producto o 
                            características de cualquier Producto a usted o a los Usuarios en general, o crear límites de 
                            uso para cualquier Producto. Podemos cancelar o suspender de manera permanente o temporal 
                            su acceso a cualquier Producto sin aviso ni responsabilidad por cualquier causa, lo que incluye 
                            si a discreción exclusiva infringe cualquier disposición de estas Condiciones o sin causa alguna. 
                            Tras la cancelación por cualquier causa o sin causa alguna, usted permanece vinculado por 
                            estas Condiciones.</p>
                        <h3>5. Software móvil</h3>
                        <h4>A. Software móvil</h4>
                        <p>Podemos hacer que el software disponible acceda a los Productos a través del dispositivo móvil 
                            («<b>Software móvil</b>»). A efectos de usar el Software móvil, debe contar con un dispositivo móvil 
                            que sea compatible con el Software móvil. GVIS no garantiza que el Software móvil vaya a ser compatible 
                            con su dispositivo móvil. Puede usar los datos móviles en conexión con el Software móvil y puede incurrir 
                            en cargos adicionales de su proveedor de telefonía inalámbrica por estos servicios. Acepta que es 
                            exclusivamente responsable de cualquiera de dichos cargos. Por la presente, GVIS le otorga una 
                            licencia no exclusiva, no transferible y revocable para usar una copia del código compilado del Software 
                            móvil para una cuenta en un dispositivo móvil perteneciente o arrendado únicamente a usted para su uso 
                            personal. El usuario no podrá: (i) modificar, desmontar, descompilar o aplicar ingeniería inversa al 
                            Software móvil, excepto en la medida que dicha restricción se prohíba expresamente por la ley; (ii) 
                            alquilar, arrendar, prestar, revender, sublicenciar, distribuir o transferir de otra manera el Software 
                            móvil a cualquier tercero o usar el Software móvil para proveer tiempo compartido o servicios similares 
                            para cualquier tercero; (iii) hacer cualesquiera copias del Software móvil; (iv) remover, eludir, 
                            inhabilitar, dañar o interferir de otra manera con características relacionadas a la seguridad del 
                            Software móvil, características que previenen o restringen el uso o la copia de cualquier contenido al 
                            que pueda acceder el Software móvil, o características que impongan limitaciones al uso del Software móvil o 
                            (v) eliminar las notificaciones de derechos de autor y otros derechos sobre la propiedad en el Software móvil. 
                            Reconoce que GVIS puede emitir ocasionalmente versiones actualizadas del Software móvil y que puede 
                            actualizar electrónica y automáticamente la versión del Software móvil que usted utiliza en su 
                            dispositivo móvil. Da su consentimiento para dicha actualización automática en su dispositivo móvil y 
                            acepta que los términos y condiciones de estas Condiciones aplicarán a todas las actualizaciones. 
                            La fuente abierta aplicable o la licencia de tercero EULA, si corresponde, abarcan cualquier código 
                            de un tercero que se pueda incorporar al Software móvil, las que autorizan el uso de dicho código. 
                            La cesión de licencia que antecede no es una venta del Software móvil o cualquier copia de este, y 
                            GVIS o sus terceras partes proveedoras o socias retienen todo derecho, título y participación en el 
                            Software móvil (y cualquier copia de este). Cualquier intento por su parte de transferir cualquier derecho, 
                            deber u obligación en adelante, excepto tal como se provee expresamente en estas Condiciones, es nulo. 
                            GVIS se reserva todos los derechos que no le sean expresamente otorgados de conformidad con estas Condiciones. 
                            El Software móvil se origina en Colombia y está sujeto a las leyes y regulaciones de exportación Colombia. 
                            Es posible que el Software móvil no sea exportado o reexportado a determinados países o a aquellas 
                            personas o entidades que tienen prohibida la recepción de exportaciones de Colombia. Además, 
                            el Software móvil puede estar sujeto a las leyes de importación y exportación de otros países. 
                            Acepta cumplir con todas las leyes de Colombia y del extranjero con relación al uso del Software móvil y 
                            los Productos.</p>
                        <h4>B. Software móvil de la tienda de aplicaciones de Apple</h4>
                        <p>Lo siguiente aplica a cualquier Software móvil que adquiera de la tienda de aplicaciones 
                            («<b>Software procedente de la tienda de aplicaciones</b>»): Reconoce y acepta que estas Condiciones 
                            aplican únicamente entre usted y GVIS, no Apple, Inc. («<b>Apple</b>») y que Apple no tiene 
                            responsabilidad alguna por el sofware procedente de la tienda de aplicaciones o su contenido. 
                            Su uso del software procedente de la tienda de aplicaciones debe cumplir con los Términos de 
                            servicio de la tienda de aplicaciones. Usted reconoce que Apple no tiene obligación alguna de 
                            facilitar cualesquiera servicios de mantenimiento y soporte con relación al Software procedente 
                            de la tienda de aplicaciones. En el caso de cualquier imposibilidad del Software 
                            procedente de la tienda de aplicaciones de cumplir con cualquier garantía aplicable, 
                            usted puede notificar a Apple y Apple puede reembolsarle el precio de la compra del 
                            Software procedente de la tienda de aplicaciones; en la medida máxima permitida por la ley 
                            aplicable, Apple no tendrá otra obligación de garantía con respecto al Software procedente 
                            de la tienda de aplicaciones, y cualquier otro reclamo, pérdida, responsabilidad, daños y 
                            perjuicios, costos o gastos atribuibles a cualquier imposibilidad de cumplimiento de cualquier 
                            garantía será regido únicamente por estas Condiciones y cualquier ley aplicable a GVIS como proveedor 
                            del software. Usted reconoce que Apple no es responsable de responder ninguno de sus reclamos o 
                            aquellos de cualquier tercero con relación al Software procedente de la tienda de aplicaciones o 
                            su posesión y/o uso del Software procedente de la tienda de aplicaciones que incluye, de modo no 
                            taxativo: (i) reclamos de responsabilidad por el producto; (ii) cualquier reclamo de que el Software 
                            procedente de la tienda de aplicaciones no cumple con cualquier requisito legal o regulador 
                            aplicable y (iii) reclamos que surjan de la legislación para la protección del consumidor o 
                            similar, y todos dichos reclamos están regidos únicamente por estas Condiciones y cualquier 
                            ley aplicable a GVIS como proveedor del software. Usted reconoce que, en el caso de que cualquier 
                            tercero sostuviera que el Software procedente de la tienda de aplicaciones o su posesión y uso 
                            dicho Software procedente de la tienda de aplicaciones infringe los derechos de propiedad intelectual 
                            de ese tercero, GVIS, y no Apple, será el único responsable de la investigación, defensa, 
                            conciliación y cumplimiento de tal demanda por infracción de propiedad intelectual en la medida 
                            requerida por estas Condiciones. Usted y GVIS reconocen y aceptan que Apple y las subsidiarias
                            de Apple son las terceras partes beneficiarias de estas Condiciones ya que tienen relación con 
                            su licencia del Software procedente de la tienda de aplicaciones, y que, tras su aceptación de 
                            las Condiciones, Apple tendrá derecho (y se considerará que aceptó el derecho) de hacer cumplir 
                            estas Condiciones que tienen relación con su licencia del Software procedente de la tienda de 
                            aplicaciones ante usted como una tercera parte beneficiaria de estas.</p>
                        <h4>C. Software móvil de la tienda Google Play</h4>
                        <p>Lo siguiente aplica a cualquier Software móvil que adquiera de la tienda Google Play 
                            («<b>Software procedente de la tienda de Google</b>»): (i) reconoce que las Condiciones aplican 
                            únicamente entre usted y GVIS, y no con Google, Inc. («<b>Google</b>»); (ii) su uso del 
                            Software procedente de Google debe cumplir con los Términos de servicio de la tienda de Google 
                            de entonces, actualmente Google Play; (iii) Google es solo un proveedor de la tienda Google Play 
                            donde usted obtuvo el Software procedente de Google; (iv) GVIS, y no Google, es el único responsable 
                            de este Software procedente de Google; (v) Google no tiene obligación ni responsabilidad alguna 
                            ante usted con respecto al Software procedente de Google o las Condiciones, y (vi) usted reconoce 
                            y acepta que Google es una tercera parte beneficiaria de las Condiciones que tienen relación 
                            con el Software procedente de Google de GVIS.</p>
                        <h3>6. Derechos de GVIS</h3>
                        <p>Los Productos se encuentran en permanente evolución y la forma, naturaleza y/o función de los 
                            Productos puede cambiar ocasionalmente sin proveerle previo aviso. Además, GVIS puede dejar de 
                            proveer (permanente o temporalmente) los Productos (o cualesquiera características en los Productos) 
                            a usted o a otros Usuarios en general y puede no ser capaz de proveerle previo aviso. También nos 
                            reservamos el derecho de crear, asignar o brindar acceso a los productos, crear límites en el uso y 
                            almacenamiento, a nuestra entera discreción y en cualquier momento sin proveerle previo aviso. 
                            Todo derecho, título y participación sobre los Productos y respecto a ellos (lo que excluye el 
                            Contenido provisto por los Usuarios y otras terceras partes) son y seguirán siendo propiedad 
                            exclusiva de GVIS y sus licenciantes. Los Productos están protegidos por derechos de autor, 
                            marcas registradas y otras leyes tanto de Colombia como de países extranjeros. Nada en las 
                            Condiciones le da derecho a usar el nombre de GVIS o cualesquiera de las marcas registradas, 
                            logos, nombres del dominio y demás características propias de la marca. 
                            Cualquier devolución, comentario o sugerencia que pueda proveer respecto a GVIS o los Productos es 
                            de carácter completamente voluntario y seremos libres de usar tales devoluciones, 
                            comentarios o sugerencias de la forma que estimemos mejor y sin ninguna obligación para con usted.</p>
                        <h3>7. Obligaciones de registro & contraseñas</h3>
                        <p>Nunca puede usar la cuenta de otro Usuario sin permiso de GVIS. Teniendo en cuenta su uso de los 
                            Productos, acepta (a) que su información será correcta, precisa, actual y completa, y (b) 
                            mantener y actualizar inmediatamente su información para que siga siendo correcta, precisa, 
                            actual y completa. Usted es responsable de salvaguardar cualquier contraseña que use para acceder a 
                            los Productos (lo que incluye las contraseñas usadas en otros productos de GVIS que permiten una 
                            única suscripción a los Productos) y a cualquier actividad o acción con su contraseña. Lo 
                            incentivamos a usar contraseñas «fuertes» (contraseñas que usen una combinación de letras 
                            mayúsculas y minúsculas, números y símbolos, y que tengan al menos 8 (ocho) caracteres) con su cuenta. 
                            Usted acepta (a) notificar a GVIS de cualquier uso no autorizado de su contraseña o identificación 
                            de Usuario y cualquier otra filtración de seguridad y (b) asegurarse de que se desconecta de su 
                            cuenta al final de cada sesión.</p>
                        <h3>8. Controles de exportación</h3>
                        <p>Tal como lo requieren las leyes de Colombia y otros países: (a) usted comprende que los Productos 
                            están sujetos a controles de exportación conforme a las normativas aplicables; (b) usted no está 
                            localizado en un país prohibido como destino conforme a las regulaciones de sanciones de Colombia 
                            y (c) no exportará, reexportará ni transferirá los Productos a cualquier destino, personas o 
                            entidades prohibidas en la lista de entidades o la lista de personas bloqueadas y nacionales 
                            especialmente designados de la Oficina de control de activos extranjeros, o cualesquiera listas 
                            similares elaboradas por otros países, sin las licencias o autorizaciones de exportación necesarias.</p>
                        <h3>9. Cancelación</h3>
                        <p>Podemos suspender o cancelar sus cuentas o dejar de proveer la totalidad o parte de los Productos 
                            en cualquier momento y por cualquier motivo, lo que incluye, de modo no taxativo, si consideramos 
                            de manera razonable que: (i) ha infringido estas Condiciones, (ii) significa un riesgo o posible 
                            exposición legal para nosotros o (iii) proveer los Productos ya no nos resulta comercialmente viable. 
                            En dicho caso de cancelación, la licencia otorgada en adelante será automáticamente cancelada. 
                            En todos dichos casos, las Condiciones se darán por terminadas, lo que incluye, de modo no taxativo, 
                            su licencia para usar los Productos, excepto que las siguientes Secciones se deberán seguir 
                            aplicando: 2, 3, 5, 6, 9, 10 y 11. Además, si deja de usar los Productos por más de 60 días, 
                            es posible que se eliminen sus cuentas y GVIS no asume responsabilidad alguna por el Contenido 
                            eliminado luego de la inactividad. Si desea eliminar sus cuentas del Producto GVIS en su totalidad, 
                            se puede poner en contacto con nosotros a través de <a href="mailto:info@gvalor.co">info@gvalor.co </a> 
                            y especificar qué cuentas del Producto GVIS desea eliminar. Eliminaremos sus cuentas especificadas en 
                            un período de tiempo razonable salvo que lo prohíba la ley. Nada en esta Sección afectará los derechos de GVIS a 
                            cambiar, limitar o interrumpir la provisión de los Productos sin previo aviso, tal como se 
                            dispone en otro punto de las presentes Condiciones.</p>
                        <h3>10. Exenciones & limitaciones de responsabilidad</h3>
                        <p>Lea atentamente esta Sección ya que limita la responsabilidad de GVIS y sus matrices, 
                            subsidiarias, filiales, empresas relacionadas, funcionarios, directores, empleados, agentes, 
                            representantes, socios y/o licenciantes (en conjunto, las «<b>Entidades de GVIS</b>»). 
                            Cada una de las subsecciones a continuación aplica únicamente en la medida máxima permitida 
                            por la ley aplicable. Nada en esta sección pretende limitar cualquier derecho que pueda tener 
                            que no sea legítimamente limitado.</p>
                        <h4>A. Los productos se encuentran disponibles «en el estado que se encuentren».</h4>
                        <p>Su uso y acceso a los Productos o cualquier Contenido son a su propio riesgo. Comprende y acepta 
                            que los Productos se proveen a usted «en el estado que se encuentren» y «tal como se encuentren 
                            disponibles». Sin limitarse a lo que antecede, en la medida máxima permitida por la ley 
                            aplicable, LAS ENTIDADES DE GVIS RENUNCIAN A TODAS LAS GARANTÍAS Y CONDICIONES, YA SEAN EXPRESAS O 
                            IMPLÍCITAS, DE COMERCIABILIDAD, APTITUD PARA UNA FINALIDAD PARTICULAR O AUSENCIA DE INFRACCIÓN. 
                            Ningún asesoramiento o información, ya sea oral o por escrito, que se obtenga de cualquiera de las 
                            Entidades de GVIS o a través de los Productos, creará cualquier garantía que no se haya dado 
                            expresamente en la presente.</p>
                        <h4>B. EXENCIÓN DE HIPAA</h4>
                        <p>GVIS no asegura ni provee garantía alguna de que su uso de los Productos cumplirá con la Ley de 
                            portabilidad y responsabilidad de seguros médicos de 1996, tal como se enmendó («<b>HIPAA</b>», 
                            por sus siglas en inglés). Si usted es un proveedor de asistencia médica, plan de salud, 
                            aseguradora, cámara de compensación de información sobre asistencia médica u otra entidad que se 
                            pueda considerar una «entidad cubierta» conforme a la HIPAA o un «socio comercial» de una 
                            «entidad cubierta» conforme a la HIPAA, debe seguir todas las etapas necesarias para cumplir 
                            con la HIPAA y será responsable de cualquier cumplimiento aplicable de la HIPAA. Acepta que no 
                            proveerá a GVIS ninguna información médica protegida (según la definición del término en la HIPAA) 
                            y que, por lo tanto, no se considera que GVIS sea un socio comercial suyo y/o de su institución a 
                            efectos del cumplimiento de la HIPAA.</p>
                        <h4>C. Enlaces y recursos de terceros</h4>
                        <p>Los productos pueden incluir enlaces a sitios de internet o recursos de terceros. Usted reconoce y 
                            acepta que las Entidades de GVIS no son responsables por: (i) la disponibilidad o precisión de 
                            dichos sitios de internet o recursos o (ii) el contenido, los productos o servicios en dichos 
                            sitios de internet o recursos, o disponibles a través de ellos. Los enlaces a dichos sitios de 
                            internet o recursos no implican aprobación alguna de las Entidades de GVIS de dichos sitios de internet, 
                            recursos o del contenido, los productos o servicios disponibles a través de dichos sitios de internet 
                            o recursos. Usted reconoce su responsabilidad exclusiva y asume todo riesgo que surja del uso de 
                            cualquiera de dichos sitios de internet o recursos por su parte. Si accede al sitio web o servicio 
                            del Producto de un tercero o comparte su Contenido en el sitio web o servicio de cualquier tercero 
                            o a través de ellos, lo hace a su propio riesgo, y comprende que estas Condiciones y la Política 
                            de privacidad no aplican al uso que haga de tales sitios.</p>
                        <h4>D. Limitación de responsabilidad</h4>
                        <p>EN LA MEDIDA MÁXIMA PERMITIDA POR LA LEY APLICABLE, LAS ENTIDADES DE GVIS NO SERÁN RESPONSABLES DE 
                            NINGÚN DAÑO INDIRECTO, INCIDENTAL, ESPECIAL, CONSECUENCIAL O PUNITIVO O CUALQUIER LUCRO CESANTE O 
                            PÉRDIDA DE INGRESOS, YA SEA QUE SE INCURRA EN ELLA DIRECTA O INDIRECTAMENTE, O CUALQUIER PÉRDIDA DE 
                            DATOS, USO, FONDO DE COMERCIO U OTRAS PÉRDIDAS INTANGIBLES COMO CONSECUENCIA DE (i) SU USO O ACCESO O 
                            INCAPACIDAD PARA USAR O ACCEDER A LOS PRODUCTOS; (ii) CUALQUIER CONDUCTA O CONTENIDO DE CUALQUIER 
                            USARIO O TERCERO EN LOS PRODUCTOS, A TRAVÉS DE ELLOS O ASOCIADOS A ELLOS, LO QUE INCLUYE, DE MODO 
                            NO TAXATIVO, CUALQUIER CONDUCTA DIFAMATORIA, OFENSIVA O ILEGAL DE OTROS USARIOS O TERCEROS; (iii) 
                            CUALQUIER CONTENIDO OBTENIDO DE LOS PRODUCTOS O (iv) ALTERACIÓN, USO O ACCESO NO AUTORIZADO A SUS 
                            TRANSMISIONES O CONTENIDO. EN NINGÚN CASO LA RESPONSABILIDAD TOTAL DE LAS ENTIDADES DE GVIS DEBERÁ 
                            EXCEDER EL MÁXIMO DE CIEN DÓLARES AMERICANOS (US$ 100,00) O LA CANTIDAD QUE LE PAGÓ A GVIS, 
                            SI CORRESPONDE, EN LOS ÚLTIMOS SEIS MESES POR LOS PRODUCTOS QUE DAN LUGAR A LA DEMANDA. LAS 
                            LIMITACIONES DE ESTA SUBSECCIÓN APLICARÁN A CUALQUIER TEORÍA DE RESPONSABILIDAD, YA SEA CON BASE 
                            EN GARANTÍAS, CONTRATOS, ESTATUTOS, AGRAVIOS (QUE INCLUYEN NEGLIGENCIA) U OTROS, Y QUE LAS ENTIDADES 
                            DE GVIS HAYAN SIDO INFORMADAS O NO SOBRE LA POSIBILIDAD DE CUALQUIERA DE ESTOS DAÑOS Y PERJUICIOS, 
                            Y AUN SI UN RECURSO ESTABLECIDO EN LA PRESENTE NO LOGRA SU OBJETIVO ESENCIAL. LA LEGISLACIÓN 
                            FEDERAL, ALGUNOS ESTADOS, PROVINCIAS Y OTRAS JURISDICCIONES NO PERMITEN LA EXCLUSIÓN Y LAS 
                            LIMITACIONES DE DETERMINADAS GARANTÍAS IMPLÍCITAS, POR LO QUE ES POSIBLE QUE LAS EXCLUSIONES 
                            PRECEDENTES NO APLIQUEN A USTED. ESTAS CONDICIONES LE OTORGAN DERECHOS LEGALES ESPECÍFICOS Y 
                            TAMBIÉN PUEDE TENER OTROS DERECHOS QUE VARÍAN SEGÚN LA JURISDICCIÓN. ADEMÁS, ALGUNAS 
                            JURISDICCIONES NO PERMITEN LA EXCLUSIÓN O LIMITACIÓN DE DAÑOS INCIDENTALES O CONSECUENCIALES, 
                            POR LO QUE ES POSIBLE QUE LAS LIMITACIONES O EXCLUSIONES QUE ANTECEDEN NO APLIQUEN A USTED. 
                            LAS EXENCIONES, EXCLUSIONES Y LIMITACIONES DE RESPONSABILIDAD CONFORME A ESTAS CONDICIONES NO 
                            APLICARÁN EN LA MEDIDA QUE LO PROHÍBA LA LEY APLICABLE.</p>
                        <h4>E. Indemnización</h4>
                        <p>Usted acepta defender, indemnizar y eximir de toda responsabilidad a GVIS y sus subsidiarias, 
                            agentes, licenciantes, gerentes y otras empresas afiliadas, y sus empleados, contratistas, 
                            agentes, funcionarios y directores, de y contra cualesquiera y todos los reclamos, daños y 
                            perjuicios, obligaciones, pérdidas, responsabilidades, costos o deudas, y gastos (los que incluyen, 
                            de modo no taxativo, los honorarios del abogado) que surjan de: (i) su uso y acceso a un 
                            Producto, lo que incluye cualquier dato o contenido transmitido o recibido por usted; (ii) 
                            su infracción de cualquier disposición de estas Condiciones, lo que incluye, de modo no 
                            taxativo, su incumplimiento de cualquiera de las representaciones y garantías anteriores; (iii) 
                            su infracción de cualquier derecho de un tercero, lo que incluye, de modo no taxativo, 
                            cualquier derecho de privacidad o derecho de propiedad intelectual; (iv) su infracción de 
                            cualquier ley, norma o regulación aplicable; (v) cualquier Contenido que se envíe a través de 
                            su cuenta de Usuario, lo que incluye, de modo no taxativo, información engañosa, falsa o 
                            imprecisa; (vi) mala conducta intencional o (vii) el uso y acceso de cualquier otro tercero a 
                            un Producto con un nombre de usuario único, contraseña u otro código de seguridad apropiado.</p>
                        <h3>11. Términos generales</h3>
                        <h4>A. Exención & divisibilidad</h4>
                        <p>La incapacidad de GVIS para hacer cumplir cualquier derecho o disposición de estas Condiciones 
                            no se considerarán una exención de dicho derecho o disposición. En el caso de que cualquier 
                            disposición de estas Condiciones se considere inválida o inaplicable, entonces tal disposición 
                            será limitada o eliminada en la medida mínima necesaria, y las disposiciones restantes de estas 
                            Condiciones seguirán en plena vigencia y efecto; excepto en el caso que no se pueda aplicar 
                            la exención de demanda colectiva universal/juicio con jurado establecida en la Sección 11(B)(iii) 
                            a continuación, la totalidad del acuerdo de arbitraje será inaplicable.</p>
                        <h4>B. Ley aplicable; resolución de conflictos</h4>
                        <p><b>(i) Ley aplicable</b>. Estas Condiciones y cualquier acción relacionada con ellas será 
                        regulada por las leyes de Colombia sin considerar o aplicar sus disposiciones sobre conflictos de 
                        leyes o su estado o país de residencia. Toda demanda, proceso judicial o litigio que surja con 
                        relación a los Productos se llevará únicamente ante los tribunales de Colombia, y usted acepta 
                        la jurisdicción y la competencia territorial de dichos tribunales y renuncia a cualquier objeción 
                        de foro inconveniente.</p>
                        <p><b>(ii) Arbitraje</b>. LEA ATENTAMENTE ESTA SECCIÓN PORQUE REQUIERE QUE LAS PARTES SOMETAN SUS CONFLICTOS 
                        A ARBITRAJE Y LIMITA LA FORMA EN QUE PUEDE SOLICITAR MEDIDAS CAUTELARES A GVIS. <br></br> 
                        En el caso de cualquier conflicto con GVIS, usted acepta primero contactarnos a 
                        <a href="mailto:info@gvalor.co"> info@gvalor.co </a> e intentar resolver el conflicto con nosotros de 
                        manera informal. En el caso poco probable de que GVIS no sea capaz de resolver un conflicto 
                        que tiene con usted luego de 60 (sesenta) días, cada uno acuerda resolver cualquier demanda, 
                        conflicto o controversia (lo que excluye cualquier demanda por mandato judicial o cualquier otra 
                        medida de Equidad, ta como se proveen más adelante) que surja de estas Condiciones o con relación o 
                        referencia a ellas, o del incumplimiento o supuesto incumplimiento de estas, mediante arbitraje 
                        vinculante, conforme a los Procedimientos de arbitraje determinados por las regulaciones Colombianas, 
                        excepto tal como se provee en la presente. Si usa los Productos con fines comerciales, cada parte será 
                        responsable de pagar cualquier tasa de presentación, administración o mediación, y el laudo emitido por 
                        el árbitro incluirá los costos de arbitraje, honorarios razonables del abogado y costos razonables por 
                        expertos y otros testigos. Si usted es un individuo que usa los Productos con fines no comerciales: (i) 
                        puede estar sujeto a pagar una tasa para iniciar su caso, salvo que lo solicite y tenga éxito en la 
                        obtención de una exención de tasas; (ii) el laudo emitido por el árbitro puede incluir sus costos de 
                        arbitraje, sus honorarios razonables para el abogado y sus costos razonables por expertos y testigos y 
                        (iii) usted puede demandar ante un tribunal de casos menores con jurisdicción competente sin participar 
                        antes en un arbitraje, pero esto no lo absuelve de su compromiso a participar en el proceso informal de 
                        resolución de conflictos. Cualquier resolución sobre el laudo emitido por el árbitro se puede registrar 
                        ante cualquier tribunal con jurisdicción competente. Se considerará que nada en esta Sección previene que 
                        GVIS busque un mandato judicial u otra medida de Equidad de los tribunales según sea necesario para prevenir 
                        la infracción real o la amenaza de esta, apropiación indebida o infracción de nuestra seguridad de datos, 
                        derechos de propiedad intelectual u otros derechos de propiedad.</p>
                        <p><b>(iii) Exención de demanda colectiva/juicio con jurado</b>. CON RESPECTO A TODA PERSONA Y ENTIDAD, 
                        INDEPENDIENTEMENTE DE SI OBTUVIERON O USARON LOS PRODUCTOS CON FINES PERSONALES, COMERCIALES U OTROS, 
                        TODAS LAS DEMANDAS SE DEBEN PRESENTAR EN LA CAPACIDAD INDIVIDUAL DE LAS PARTES Y NO COMO DEMANDANTE O 
                        MIEMBRO DE UN GRUPO EN CUALQUIER PRESUNTA DEMANDA CONJUNTA, DEMANDA COLECTIVA, DEMANDA GENERAL DE ABOGADO 
                        PRIVADO U OTRO PROCESO REPRESENTATIVO. ESTA EXENCIÓN APLICA A TODO ARBITRAJE COLECTIVO Y, SALVO QUE SE ACUERDE 
                        LO CONTRARIO, EL ÁRBITRO NO PUEDE CONSOLIDAR LAS DEMANDAS DE MÁS DE 1 (UNA) PERSONA. ACEPTA QUE, 
                        AL SUSCRIBIR A ESTAS CONDICIONES, USTED Y GVIS RENUNCIAN AL DERECHO A UN JUICIO CON JURADO O A 
                        PARTICIPAR EN UNA DEMANDA CONJUNTA, DEMANDA COLECTIVA, DEMANDA GENERAL DE ABOGADO PRIVADO U OTRO PROCESO 
                        REPRESENTATIVO DE CUALQUIER TIPO CON RELACIÓN A LOS PRODUCTOS O ESTAS CONDICIONES. </p>
                        <p><b>(iv) Aplicabilidad limitada de arbitraje y exención de demanda colectiva/juicio con jurado</b>. 
                        Es posible que determinadas leyes, normas y regulaciones que apliquen a usted no le permitan 
                        acordar previamente al arbitraje vinculante o a renunciar a su derecho a sumarse a un proceso 
                        representativo o renunciar a su derecho a un juicio con jurado. Si tales leyes aplican a usted, 
                        los requisitos de arbitraje establecidos en la subsección (ii) y/o la exención de demanda 
                        colectiva/juicio con jurado establecida en la subsección (iii) no aplican a usted.</p>
                        <h4>C. Totalidad del acuerdo</h4>
                        <p>Estas Condiciones y nuestra 
                            <Link to="/politica-de-privacidad"> Política de privacidad </Link>
                        son la totalidad del acuerdo exclusivo entre GVIS y usted respecto a los Productos, y 
                        estas Condiciones sustituyen y reemplazan cualquier acuerdo previo entre GVIS y usted respecto a los 
                        Productos. Salvo por los miembros del grupo de empresas de las que GVIS es matriz, ninguna otra persona o 
                        empresa serán terceras partes beneficiarias de las Condiciones. No obstante lo que antecede, nada en 
                        estas Condiciones sustituye o limita sus derechos conforme a (1) los términos y condiciones de cualquier 
                        acuerdo por escrito que se haya celebrado con GVIS respecto al uso de los Productos o (2) las 
                        leyes o regulaciones aplicables en la medida que estas Condiciones estén prohibidas por tales leyes o 
                        regulaciones. En el caso de cualquier conflicto entre estas Condiciones y los términos y condiciones de un 
                        acuerdo por escrito aplicable que se haya celebrado con GVIS, prevalecerán los términos y condiciones del 
                        acuerdo por escrito. Ocasionalmente, puede ser que revisemos estas Condiciones, a cuya versión más 
                        actual se accederá mediante un enlace en https://gvalor.co/. Si la revisión, a nuestra entera discreción, 
                        es material, le notificaremos a través de los Productos o a través del correo electrónico asociado a su 
                        perfil. Al continuar con el uso o acceso a los Productos luego de que tales revisiones entran en 
                        vigencia, usted acepta estar sujeto a las Condiciones revisadas.</p>
                        <p>Estos Productos son operados y están provistos por GVIS;</p>
                            <ul>Grupo Valor International Solutions SAS.</ul>
                            <ul>Carrera 69D 1 – 51 sur Apto 619.</ul>
                            <ul>Bogota Colombia.</ul>
                        <p>Si tiene alguna duda respecto a estas Condiciones, sírvase enviar un correo electrónico a: 
                            <a href="mailto:info@gvalor.co"> info@gvalor.co.</a></p>
                        <h3>Entrada en vigor: 01 de enero de 2020</h3>
                    </Col>
                </Row>
            </Container>
        </div>
  );
}

export default UseTerms;
