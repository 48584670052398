import React, { useEffect } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import { SliderZoom, SliderSlide } from '../components/slider';
import img1 from "../img/Slider1.jpg";
import img2 from "../img/Slider2.jpg";
import img3 from "../img/Slider3.jpg";
import cl1 from "../img/Cl1.png";
import cl2 from "../img/Cl2.png";
import cl3 from "../img/Cl3.png";
import cl4 from "../img/Cl4.png";
import cl5 from "../img/Cl5.png";
import cl6 from "../img/Cl6.png";
import cl7 from "../img/Cl7.png";
import cl8 from "../img/Cl8.png";
import cl9 from "../img/Cl9.png";
import cl10 from "../img/Cl10.png";
import cl11 from "../img/Cl11.png";
import cl12 from "../img/Cl12.png";
import cl13 from "../img/Cl13.png";
import cl14 from "../img/Cl14.png";
import cl15 from "../img/Cl15.png";
import { IoIosRocket, IoIosBrush, IoIosVideocam } from "react-icons/io";
import { FaGraduationCap } from "react-icons/fa";
import { GiCutDiamond } from 'react-icons/gi';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const sliderImgs = [
    {
        img: img1,
        texto: {
            titulo: '¿Institución educativa online?',
            descripcion: 'Potencializa los programas educativos de tu institución con un ambiente 100% en línea',
        },
        accion: {
            href: '/rocket',
            texto: 'Más detalles'
        }
    },
    {
        img: img2,
        texto: {
            titulo: 'Dale vida a tus contenidos',
            descripcion: '¡Captura la atención de tus estudiantes con hermosos contenidos dinámicos, animados e interactivos!',
        },
        accion: {
            href: '/contenidos',
            texto: 'Ver el demo'
        }
    },
    {
        img: img3,
        texto: {
            titulo: 'Controla lo que pasa con tu inventario',
            descripcion: 'Gestiona el inventario de tu negocio o emprendimiento de una manera facil y rapida.',
        },
        accion: {
            href: '/cristal',
            texto: 'Conocer cristal'
        }
    },
];

const ClientsLogos = [
    cl1, cl2, cl3, cl4, cl5, cl6, cl7, cl8, cl9, cl10, cl11, cl12, cl13, cl14, cl15
];

const Home = () => {

    const history = useHistory();

    const { pathname } = useLocation();

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[pathname]);

    return (
        <div>
            <SliderZoom imgs={sliderImgs} showNavArrows={false} />
            
            <Row className='third-section'>
                <Container >
                    <Col className="text-center mt-3 mb-3">
                        <h3>En Grupo Valor trabajamos con todo nuestro esfuerzo, para acompañar y aportar a nuestros clientes 
                            servicios tecnológicos, humanos y conocimiento, que les permita complementar y aportar valor a sus 
                            instituciones educativas y/o empresas. <br></br><br></br>
                            Garantizamos precios competitivos, calidad, cumplimiento y profesionalismo.</h3>
                    </Col>
                </Container>
            </Row>
            
            <Row className='secondary-section justify-content-center'>
                <Row>
                    <Col className='text-center'>
                        <h4 className='white-text'>¿Cómo podemos ayudarte?</h4>
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    <Col sm={'auto'} className='text-center'>
                        <Row>
                            <Col >
                                <Button onClick={()=>history.push('/rocket')} className='circle' variant='light'><IoIosRocket style={{padding: '2rem'}} color="rgba(66,187,236,1)" size='9rem'/></Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='pt-2'>
                                <h5 className='white-text'>Rocket</h5>
                                <p className='white-text'>Sistema de gestión académico</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={'auto'} className='text-center'>
                        <Row>
                            <Col>
                            <Button onClick={()=>history.push('/croom')} className='circle' variant='light'><FaGraduationCap style={{padding: '2rem'}} color="rgba(66,187,236,1)" fontSize="9rem"/></Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='pt-2'>
                                <h5 className='white-text'>Croom</h5>
                                <p className='white-text'>Aulas virtuales</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={'auto'} className='text-center'>
                        <Row>
                            <Col>
                                <Button onClick={()=>history.push('/vroom')} className='circle' variant='light'><IoIosVideocam style={{padding: '2rem'}} color="rgba(66,187,236,1)" fontSize="9rem"/></Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='pt-2'>
                                <h5 className='white-text'>Vroom</h5>
                                <p className='white-text'>Sala de videoconferencia</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={'auto'} className='text-center'>
                        <Row>
                            <Col>
                                <Button onClick={()=>history.push('/contenidos')} className='circle' variant='light'><IoIosBrush style={{padding: '2rem'}} color="rgba(66,187,236,1)" fontSize="9rem"/></Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='pt-2'>
                                <h5 className='white-text'>Contenidos</h5>
                                <p className='white-text'>Desarrollo de contenidos</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={'auto'} className='text-center'>
                        <Row>
                            <Col>
                                <Button onClick={()=>history.push('/cristal')} className='circle' variant='light'><GiCutDiamond style={{padding: '2rem'}} color="rgba(66,187,236,1)" fontSize="9rem"/></Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='pt-2'>
                                <h5 className='white-text'>Cristal</h5>
                                <p className='white-text'>Sistema de control de inventario</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Row>
            <Row className='third-section'>
                <Col className="text-center">
                    <h4>¿Quiénes dan vida a nuestros servicios?</h4>
                </Col>
            </Row>
            <Row className='third-section'>
                <Col>
                    <SliderSlide imgs={ClientsLogos} showNavArrows={false} showIndicators={false}/>
                </Col>
            </Row>
        </div>
    );
};

export default Home;