import React from "react";
import { Zoom, Slide } from "react-slideshow-image";
import { Button } from "react-bootstrap";
import 'react-slideshow-image/dist/styles.css';
import { GrNext, GrPrevious } from "react-icons/gr";
import { LinkContainer } from "react-router-bootstrap";

const SliderZoom = (props) => {
    const sliderImg = props.imgs;
    const height = props.height; //Valores recomendados 450, 720
    const zoomInProps = {
        indicators: true,
        scale: 1.4,
        pauseOnHover: true,
        arrows: typeof props.showNavArrows === 'boolean' ? props.showNavArrows : true,
        prevArrow: <GrPrevious size={"30"}/>,
        nextArrow: <GrNext size={"30"}/>
    };

    return (
        <Zoom {...zoomInProps}>
            {sliderImg.map((each, index) => (
                <div key={index} style={{ width: '100%', height: height ? height : 720 }}>
                    <img style={{ objectFit: "cover", width: '100%', height: height ? height : 720 }} src={each.img} alt="slider"/>
                    {each.texto && 
                        <div className={"slider-text-container " + (height ? "slider-title-"+height : "slider-title-720")} >
                            <p className='text-title'>{each.texto.titulo}</p>
                            <div className="slider-text">
                                <p className='text-description'>{each.texto.descripcion}</p>
                                {each.accion && 
                                    <LinkContainer to={each.accion.href}>
                                        <Button className='btn-gvis text-title' href={each.accion.href} variant='outline-secondary'>{each.accion.texto}</Button>
                                    </LinkContainer>
                                }
                            </div>
                        </div>
                    }
                </div>
            ))}
        </Zoom>
    )
};

const SliderStatic = (props) => {
    const sliderImg = props.imgs;
    const zoomInProps = {
        indicators: false,
        scale: 1.4,
        pauseOnHover: false,
        autoplay: false,
        arrows: false,
    };

    return (
        <Zoom {...zoomInProps}>
            {sliderImg.map((each, index) => (
                <div key={index} style={{ width: '100%', height: 450 }}>
                    <img style={{ objectFit: "cover", width: '100%', height: 450 }} src={each.img} alt="slider"/>
                    {each.texto && 
                        <div className="slider-static-title slider-text-container">
                            <p className='text-title'>{each.texto.titulo}</p>
                            <div className="slider-text">
                                <p className='text-description'>{each.texto.descripcion}</p>
                                {each.accion && 
                                    <LinkContainer to={each.accion.href}>
                                        <Button className='text-title' href={each.accion.href} variant='outline-secondary'>{each.accion.texto}</Button>
                                    </LinkContainer>
                                }
                            </div>
                        </div>
                    }
                </div>
            ))}
        </Zoom>
    )
};

const SliderSlide = (props) => {
    const sliderImg = props.imgs;
    const slideProps = {
        duration: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        pauseOnHover: false,
        autoplay: true,
        arrows: typeof props.showNavArrows === 'boolean' ? props.showNavArrows : false,
        indicators: typeof props.showIndicators === 'boolean' ? props.showIndicators : false,
    }

    return (
        <Slide {...slideProps} >
            {sliderImg.map((each, index) => (
                <div className="text-center" key={index} style={{width: '100%'}}>
                    <img className='slider-slide' src={each} alt={'logo'} />
                </div>
            ))}
        </Slide>
    )
};

export {
   SliderZoom,
   SliderSlide,
   SliderStatic,
};