import React, { useState, useEffect } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { SliderStatic  } from "../components/slider";
import contenidos from "../img/contenidos.png";
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import { BiBusSchool } from "react-icons/bi";
import { GiSchoolBag, GiTie } from "react-icons/gi";
import { IoMdSchool } from "react-icons/io";
import { FaPhotoVideo } from "react-icons/fa";
import { CgGames } from "react-icons/cg";
import { DemoPreschool, DemoColegio, DemoPregrado, 
    DemoPrivado, DemoVideos, DemoGamificacion } from "../components/demos";
import CanvasContactForm from "../components/canvasContactForm";
import { useLocation } from 'react-router-dom';

const sliderImgs = [
    {
        img: contenidos,
        texto: {
            titulo: '¡Dale vida a tus contenidos educativos!',
            descripcion: '¿Tus contenidos educativos digitales son monótonos? Conviertelos en contenidos dinámicos, interactivos, innovadores y motivadores.',
        },
    },
];

const Contenidos = () => {

    const [demo, setDemo] = useState(0);

    const [show, setShow] = useState(false);

    const loadDemo = () => {

        switch (demo) {
            case 0:
                return (<DemoPreschool />)
            case 1:
                return (<DemoColegio />)
            case 2:
                return (<DemoPregrado />)
            case 3:
                return (<DemoPrivado />)
            case 4:
                return (<DemoVideos />)
            case 5:
                return (<DemoGamificacion />)
            default:
                break;
        }
    };

    const offCanvas = () => {
        return (<CanvasContactForm onHide={setShow} placement='end' msg='Hola! busco más información sobre sus servicios de desarrollo de contenidos. Por favor contactarme'/>)
    };

    const { pathname } = useLocation();

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[pathname]);

    return (
        <div className='mt-5'>
            <Row className='pt-4'>
                <Col>
                    <SliderStatic imgs={sliderImgs}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <hr className='gvis-separator'></hr>
                </Col>
            </Row>
            <Row className="third-section pt-5">
                <Container >
                    <Row>
                        <Col className="text-center">
                            <h2>Creamos atractivos contenidos digitales adecuados a tus necesidades.</h2>
                            <h3 className="pt-3">A continuación algunos demos:</h3>
                        </Col>
                    </Row>
                    <Row className="mt-5 align-items-center">
                        <Col sm={'auto'}>
                            <Timeline align="alternate">
                                <TimelineItem >
                                    <TimelineSeparator>
                                        <TimelineDot style={{backgroundColor: "rgb(3,158,218,1)"}}/>
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent style={{fontWeight: 700}}>Selecciona cada icono para ver los demos</TimelineContent>
                                </TimelineItem>
                                <TimelineItem >
                                    <TimelineOppositeContent>
                                        Preescolar
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot style={{backgroundColor: "rgb(3,158,218,1)"}}>
                                            <Button variant='link' className='p-0' onClick={()=>setDemo(0)}><BiBusSchool color={'white'} size={30}/></Button>
                                        </TimelineDot>
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Paper elevation={3} className='p-3'>
                                            <p>Contenidos altamente gamificados</p>
                                        </Paper>
                                    </TimelineContent>
                                </TimelineItem>
                                <TimelineItem >
                                    <TimelineOppositeContent>
                                        Primaria, secundaria
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot style={{backgroundColor: "rgb(0,143,198,1)"}}>
                                            <Button variant='link' className='p-0' onClick={()=>setDemo(1)}><GiSchoolBag color={'white'} size={30}/></Button>
                                        </TimelineDot>
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Paper elevation={3} className='p-3'>
                                            <p>Contenidos motivadores</p>
                                        </Paper>
                                    </TimelineContent>
                                </TimelineItem>
                                <TimelineItem >
                                    <TimelineOppositeContent>
                                        Pregrado, posgrado
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot style={{backgroundColor: "rgb(0,135,187,1)"}}>
                                            <Button variant='link' className='p-0' onClick={()=>setDemo(2)}><IoMdSchool color={'white'} size={30}/></Button>
                                        </TimelineDot>
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Paper elevation={3} className='p-3'>
                                            <p>Contenidos interactivos</p>
                                        </Paper>
                                    </TimelineContent>
                                </TimelineItem>
                                <TimelineItem >
                                    <TimelineOppositeContent>
                                        Sector público, privado
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot style={{backgroundColor: "rgb(4,112,154,1)"}}>
                                            <Button variant='link' className='p-0' onClick={()=>setDemo(3)}><GiTie color={'white'} size={30}/></Button>
                                        </TimelineDot>
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Paper elevation={3} className='p-3'>
                                            <p>Contenidos dinámicos</p>
                                        </Paper>
                                    </TimelineContent>
                                </TimelineItem>
                                <TimelineItem >
                                    <TimelineOppositeContent>
                                        Animaciones
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot style={{backgroundColor: "rgb(0,135,187,1)"}}>
                                            <Button variant='link' className='p-0' onClick={()=>setDemo(4)}><FaPhotoVideo color={'white'} size={30}/></Button>
                                        </TimelineDot>
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Paper elevation={3} className='p-3'>
                                            <p>Videos de diversas tipologías</p>
                                        </Paper>
                                    </TimelineContent>
                                </TimelineItem>
                                <TimelineItem >
                                    <TimelineOppositeContent>
                                        Gamificación
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot style={{backgroundColor: "rgb(0,143,198,1)"}}>
                                            <Button variant='link' className='p-0' onClick={()=>setDemo(5)}><CgGames color={'white'} size={30}/></Button>
                                        </TimelineDot>
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Paper elevation={3} className='p-3'>
                                            <p>Dinámicas lúdicas que atraen</p>
                                        </Paper>
                                    </TimelineContent>
                                </TimelineItem>
                            </Timeline>
                        </Col>
                        <Col>
                            {loadDemo()}
                        </Col>
                    </Row>
                </Container>
            </Row>
            <Row className="secondary-section pt-5">
                <Col className="text-center white-text">
                    <h2>Nuestros contenidos son hechos a la medida de tus requerimientos</h2>
                    <h4>Déjanos conocer tus necesidades para generar un producto adecuado para tí</h4>
                    <Button onClick={()=>setShow(true)} className="mt-5 btn-light2" variant='light'><h5 className="mt-2">Información de contacto</h5></Button>
                </Col>
            </Row>
            {show && offCanvas()}
        </div>
    );
};

export default Contenidos;