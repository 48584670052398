import React from "react";
import { Row, Col , Card, Button } from "react-bootstrap";
import demo3 from "../img/03.png";
import demo4 from "../img/04.png";
import demo5 from "../img/05.png";
import demo9 from "../img/09.png";
import demo10 from "../img/10.png";
import demo11 from "../img/11.png";
import demo13 from "../img/13.png";
import demo14 from "../img/14.png";
import demo15 from "../img/Gamifica1.png";
import demo16 from "../img/Gamifica2.png";
import { FaCaretRight } from "react-icons/fa";

const DemoPreschool = () => {

    return (
        <div>
            <Row className="justify-content-center">
                <Col sm={6} md={12} lg={8}>
                    <Card >
                        <a rel="noopener noreferrer" href="https://gvis-sites.s3.us-east-2.amazonaws.com/gvalor.co/sectoreducativo/prescolar/demo3/story_html5.html" target="_blank"><Card.Img variant="top" src={demo4} /></a>
                        <Card.Body>
                            <Button className='btn-block btn-gvis' href="https://gvis-sites.s3.us-east-2.amazonaws.com/gvalor.co/sectoreducativo/prescolar/demo3/story_html5.html" target="_blank" variant="outline-primary"><FaCaretRight /> Ver demo</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={6} md={12} lg={8}>
                    <Card >
                        <a rel="noopener noreferrer" href="https://gvis-sites.s3.us-east-2.amazonaws.com/gvalor.co/sectoreducativo/prescolar/demo4/story_html5.html" target="_blank"><Card.Img variant="top" src={demo3} /></a>
                        <Card.Body>
                            <Button className='btn-block btn-gvis' href="https://gvis-sites.s3.us-east-2.amazonaws.com/gvalor.co/sectoreducativo/prescolar/demo4/story_html5.html" target="_blank" variant="outline-primary"><FaCaretRight /> Ver demo</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

const DemoColegio = () => {

    return (
        <div>
            <Row className="justify-content-center">
                <Col sm={6} md={12} lg={8}>
                    <Card >
                        <a rel="noopener noreferrer" href="https://gvis-sites.s3.us-east-2.amazonaws.com/gvalor.co/CursosPredisenados/Demo+Curso+Navegable+2/Demo+Curso+Navegable+2.html" target="_blank"><Card.Img variant="top" src={demo5} /></a>
                        <Card.Body>
                            <Button className='btn-block btn-gvis' href="https://gvis-sites.s3.us-east-2.amazonaws.com/gvalor.co/CursosPredisenados/Demo+Curso+Navegable+2/Demo+Curso+Navegable+2.html" target="_blank" variant="outline-primary"><FaCaretRight /> Ver demo</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={6} md={12} lg={8}>
                    <Card >
                        <a rel="noopener noreferrer" href="https://gvis-sites.s3.us-east-2.amazonaws.com/gvalor.co/gamificacion/01/index.html" target="_blank"><Card.Img variant="top" src={demo9} /></a>
                        <Card.Body>
                            <Button className='btn-block btn-gvis' href="https://gvis-sites.s3.us-east-2.amazonaws.com/gvalor.co/gamificacion/01/index.html" target="_blank" variant="outline-primary"><FaCaretRight /> Ver demo</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

const DemoPregrado = () => {

    return (
        <div>
            <Row className="justify-content-center">
                <Col sm={6} md={12} lg={8}>
                    <Card >
                        <a rel="noopener noreferrer" 
                            // href="https://gvis-sites.s3.us-east-2.amazonaws.com/gvalor.co/sectoreducativo/pregrado/csuministros/story_html5.html" 
                            href="https://gvis-sites.s3.us-east-2.amazonaws.com/gvalor.co/sectoreducativo/posgrado/rse/story_html5.html" 
                            target="_blank"><Card.Img variant="top" src={demo10} /></a>
                        <Card.Body>
                            <Button className='btn-block btn-gvis' 
                                // href="https://gvis-sites.s3.us-east-2.amazonaws.com/gvalor.co/sectoreducativo/pregrado/csuministros/story_html5.html" 
                                href="https://gvis-sites.s3.us-east-2.amazonaws.com/gvalor.co/sectoreducativo/posgrado/rse/story_html5.html" 
                                target="_blank" variant="outline-primary"><FaCaretRight /> Ver demo</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={6} md={12} lg={8}>
                    <Card >
                        <a rel="noopener noreferrer" 
                            // href="https://gvis-sites.s3.us-east-2.amazonaws.com/gvalor.co/sectoreducativo/posgrado/rrhh/story_html5.html" 
                            href="https://gvis-sites.s3.us-east-2.amazonaws.com/gvalor.co/sectoreducativo/posgrado/flipbook/story_html5.html" 
                            target="_blank"><Card.Img variant="top" src={demo11} /></a>
                        <Card.Body>
                            <Button className='btn-block btn-gvis' 
                                // href="https://gvis-sites.s3.us-east-2.amazonaws.com/gvalor.co/sectoreducativo/posgrado/rrhh/story_html5.html" 
                                href="https://gvis-sites.s3.us-east-2.amazonaws.com/gvalor.co/sectoreducativo/posgrado/flipbook/story_html5.html" 
                                target="_blank" variant="outline-primary"><FaCaretRight /> Ver demo</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

const DemoPrivado = () => {

    return (
        <div>
            <Row className="justify-content-center">
                <Col sm={6} md={12} lg={8}>
                    <Card >
                        <a rel="noopener noreferrer" 
                            href="https://gvis-sites.s3.us-east-2.amazonaws.com/gvalor.co/sectorprivado/cursos/SBN/story_html5.html" 
                            target="_blank"><Card.Img variant="top" src={demo13}/></a>
                        <Card.Body>
                            <Button 
                                className='btn-block btn-gvis' 
                                href="https://gvis-sites.s3.us-east-2.amazonaws.com/gvalor.co/sectorprivado/cursos/SBN/story_html5.html" 
                                target="_blank" variant="outline-primary"><FaCaretRight /> Ver demo</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={6} md={12} lg={8}>
                    <Card >
                        <a rel="noopener noreferrer" 
                            href="https://gvis-sites.s3.us-east-2.amazonaws.com/gvalor.co/sectorprivado/cursos/lavadoactivos/story_html5.html"                             
                            target="_blank"><Card.Img variant="top" src={demo14} /></a>
                        <Card.Body>
                            <Button className='btn-block btn-gvis' 
                                href="https://gvis-sites.s3.us-east-2.amazonaws.com/gvalor.co/sectorprivado/cursos/lavadoactivos/story_html5.html" 
                                target="_blank" variant="outline-primary"><FaCaretRight /> Ver demo</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

const DemoVideos = () => {

    return (
        <div>
            <Row className="justify-content-center">
                <Col sm={'auto'} className="text-center">
                    <video className="" width="80%" autoPlay="" loop muted="" controls controlsList="nodownload">
                        <source src="https://gvis-sites.s3.us-east-2.amazonaws.com/videos/ULimaEstrategias.mp4" type="video/mp4" />
                    </video>
                    <figcaption>
                        Video tipo Doodle / Whiteboard
                    </figcaption>
                </Col>
                <Col sm={'auto'} className="text-center">
                    <video className="" width="80%" autoPlay="" loop muted="" controls controlsList="nodownload">
                        <source src="https://gvis-sites.s3.us-east-2.amazonaws.com/videos/video4.mp4" type="video/mp4" />
                    </video>
                    <figcaption>
                        Video tipo Animado
                    </figcaption>
                </Col>
                <Col sm={'auto'} className="text-center">
                    <video className="" width="80%" autoPlay="" loop muted="" controls controlsList="nodownload" preload="metadata">
                        <source src="https://gvis-sites.s3.us-east-2.amazonaws.com/videos/Video_introductorio_curso.mp4#t=80.00" type="video/mp4" />
                    </video>
                    <figcaption>
                        Video tipo Animado
                    </figcaption>
                </Col>
                {/* <Col sm={'auto'} className="text-center">
                    <video className="" width="80%" autoPlay="" loop muted="" controls controlsList="nodownload">
                        <source src="https://gvis-sites.s3.us-east-2.amazonaws.com/videos/Introduccio%CC%81nPrograma.mp4" type="video/mp4" />
                    </video>
                    <figcaption>
                        Video tipo Comic
                    </figcaption>
                </Col> */}
                <Col sm={'auto'} className="text-center">
                    <video className="" width="80%" autoPlay="" loop muted="" controls controlsList="nodownload">
                        <source src="https://gvis-sites.s3.us-east-2.amazonaws.com/videos/4Video_presentador.mp4" type="video/mp4" />
                    </video>
                    <figcaption>
                        Video tipo Presentación
                    </figcaption>
                </Col>
                <Col sm={'auto'} className="text-center">
                    <video className="" width="80%" autoPlay="" loop muted="" controls controlsList="nodownload" preload="metadata">
                        <source src="https://gvis-sites.s3.us-east-2.amazonaws.com/videos/C9-M0-Video-Intro.mp4#t=15.00" type="video/mp4" />
                    </video>
                    <figcaption>
                        Video tipo Presentación
                    </figcaption>
                </Col>
            </Row>
        </div>
    );
};

const DemoGamificacion = () => {

    return (
        <div>
            <Row className="justify-content-center">
                <Col sm={6} md={12} lg={8}>
                    <Card >
                        <a rel="noopener noreferrer" href="https://gvis-sites.s3.us-east-2.amazonaws.com/gvalor.co/gamificacion/ruleta/index.html" target="_blank"><Card.Img variant="top" src={demo15} /></a>
                        <Card.Body>
                            <Button className='btn-block btn-gvis' href="https://gvis-sites.s3.us-east-2.amazonaws.com/gvalor.co/gamificacion/ruleta/index.html" target="_blank" variant="outline-primary"><FaCaretRight /> Ver demo</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={6} md={12} lg={8}>
                    <Card >
                        <a rel="noopener noreferrer" href="https://gvis-sites.s3.us-east-2.amazonaws.com/gvalor.co/sectorprivado/gamification/6Crucigrama/story_html5.html" target="_blank"><Card.Img variant="top" src={demo16} /></a>
                        <Card.Body>
                            <Button className='btn-block btn-gvis' href="https://gvis-sites.s3.us-east-2.amazonaws.com/gvalor.co/sectorprivado/gamification/6Crucigrama/story_html5.html" target="_blank" variant="outline-primary"><FaCaretRight /> Ver demo</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export {
    DemoPreschool,
    DemoColegio,
    DemoPregrado,
    DemoPrivado,
    DemoVideos,
    DemoGamificacion,
};