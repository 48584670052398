import React, { useState, useEffect } from "react";
import { Row, Col, Container, Card, Button} from "react-bootstrap";
import { SliderStatic } from '../components/slider';
import croom from "../img/croom.jpg";
import { BsAppIndicator, BsColumnsGap, BsTablet } from 'react-icons/bs';
import { BiTerminal } from "react-icons/bi";
import { FaGraduationCap } from "react-icons/fa";
import { IoMdCheckmark } from "react-icons/io";
import { HiOutlineShoppingBag } from 'react-icons/hi';
import CanvasContactForm from "../components/canvasContactForm";
import { useLocation } from 'react-router-dom';

const sliderImgs = [
    {
        img: croom,
        texto: {
            titulo: '¡Educación en línea para todas las edades!',
            descripcion: 'Todo el poder de Moodle, en nuestra infraestructura profesional de alta disponibilidad',
        },
    },
];

const croomCompartido = [
    'Hasta 500 estudiantes',
    'Cursos ilimitados',
    '5Gb de almacenamiento para los contenidos de tus cursos',
    'Integración vroom incluida',
    'Hasta 50 participantes en todas las sesiones simultáneas de videoconferencia',
    '5Gb almacenamiento para las grabaciones',
    'Soporte técnico.',
];

const croomExclusivo = [
    'Apartir de 500 estudiantes',
    'Cursos ilimitados',
    '500Gb de almacenamiento para los contenidos de tus cursos',
    'Integración vroom incluida',
    'Hasta 150 participantes en todas las sesiones simultáneas de videoconferencia',
    '500Gb almacenamiento para las grabaciones',
    'Soporte técnico.',
    'Soporte funcional.',
    'Acceso desde de tu propio dominio institucional',
    'Personalización de look and feel de acuerdo a tu marca institucional',
];

const Croom = () => {

    const [show, setShow] = useState(false);

    const offCanvas = () => {
        return (<CanvasContactForm onHide={setShow} placement='end' msg='Hola! busco más información sobre el servicio de CROOM. Por favor contactarme'/>)
    };

    const { pathname } = useLocation();

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[pathname]);

    return (
        <div className='mt-5'>
            <Row className='pt-4'>
                <Col>
                    <SliderStatic imgs={sliderImgs}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <hr className='gvis-separator'></hr>
                </Col>
            </Row>
            <Container >
                <Row className="justify-content-center">
                    <Col xs={'auto'} sm={6} md={6} className="text-center">
                        <Card >
                            <Card.Header>Croom</Card.Header>
                            <Card.Body>
                                <Card.Title>Compartido</Card.Title>
                                <Card.Title><b><i>USD $ 1.99</i></b><br></br><i className="text-muted text-size-s">Por estudiante al mes</i></Card.Title>
                                <Button className="btn-gvis" onClick={()=>setShow(true)} variant="outline-primary" alt='¡Lo quiero!' size="block"><HiOutlineShoppingBag /></Button>
                                <Card.Text className="pt-3">
                                    Todo el poder de Moodle en nuestro hosting profesional, integrado con nuestro servicio de videoconferencia vroom. 
                                    Inscribe a tus estudiantes y profesores en sus aulas virtuales, desde donde podrán participar
                                    en actividades sincrónicas, con un excelente servicio de soporte técnico.
                                </Card.Text>
                                <Card.Text>
                                    {croomCompartido.map((element, index)=>(
                                        <span key={index}>
                                            <IoMdCheckmark /> {element}<br></br>
                                        </span>
                                    ))}
                                </Card.Text>
                                <Button className="btn-gvis" onClick={()=>setShow(true)} variant="outline-primary" alt='¡Lo quiero!' size="block"><HiOutlineShoppingBag /></Button>
                            </Card.Body>
                            <Card.Footer className="text-muted"><FaGraduationCap /></Card.Footer>
                        </Card>
                    </Col>
                    <Col xs={'auto'} sm={6} md={6} className="text-center">
                        <Card >
                            <Card.Header>Croom</Card.Header>
                                <Card.Body>
                                    <Card.Title>Exclusivo</Card.Title>
                                    <Card.Title><b><i>USD $ 1.49</i></b><br></br><i className="text-muted text-size-s">Por estudiante al mes</i></Card.Title>
                                    <Button className="btn-gvis" onClick={()=>setShow(true)} variant="outline-primary" alt='¡Lo quiero!' size="block"><HiOutlineShoppingBag /></Button>
                                    <Card.Text className="pt-3">
                                        Contarás con todos los beneficios de <i>Croom compartido </i> 
                                        pero en un ambiente 100% dedicado para tu institución educativa, 
                                        con personalización exclusiva alineada a tu marca institucional,
                                        y en una infraestructura profesional, de alta disponibilidad, 
                                        con un uptime mínimo de 99.99%.
                                    </Card.Text>
                                    <Card.Text>
                                        {croomExclusivo.map((element, index)=>(
                                            <span key={index}>
                                                <IoMdCheckmark /> {element}<br></br>
                                            </span>
                                        ))}
                                    </Card.Text>
                                    <Button className="btn-gvis" onClick={()=>setShow(true)} variant="outline-primary" alt='¡Lo quiero!' size="block"><HiOutlineShoppingBag /></Button>
                                </Card.Body>
                            <Card.Footer className="text-muted"><FaGraduationCap /></Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Row className="pt-3 pb-2">
                <Col className="text-center">
                    <i className="text-size-m">* Estos valores no incluyen impuestos</i>
                </Col>
            </Row>
            <Row className='third-section pt-5 pb-5'>
                <Col className="text-center">
                    <h2>¿Nuestro planes no se ajustan a tus necesidades?</h2>
                    <h3>¡Déjanos ponernos en contacto contigo y encontrar el plan ideal para ti!</h3>
                    <Button onClick={()=>setShow(true)} className="mt-5 btn-gvis" variant='outline-primary'><h5 className="mt-2">Información de contacto</h5></Button>
                </Col>
            </Row>
            <Row className='secondary-section'>
                <Row className='justify-content-center'>
                    <Col sm='4' className='text-center'>
                        <Row>
                            <Col>
                                <Button className='circle' variant='light'><FaGraduationCap style={{padding: '2rem'}} color="rgba(66,187,236,1)" size='9rem'/></Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='pt-2'>
                                <h5 className='white-text'>Realmente un aula virtual</h5>
                                <p className='white-text'>Entrega contenidos educativos, inicia debates entre los estudiantes, califica actividades, haz seguimiento de logros eficazmente. Aprovecha todas las caracterísiticas educativas de Moodle.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm='4' className='text-center'>
                        <Row>
                            <Col>
                                <Button className='circle' variant='light'><BsAppIndicator style={{padding: '2rem'}} color="rgba(66,187,236,1)" size='9rem'/></Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='pt-2'>
                                <h5 className='white-text'>Grabaciones</h5>
                                <p className='white-text'>Graba tus sesiones de conferencia web y compartelas con los estudiantes, podrán verlas una y otra vez.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm='4' className='text-center'>
                        <Row>
                            <Col>
                                <Button className='circle' variant='light'><BiTerminal style={{padding: '2rem'}} color="rgba(66,187,236,1)" size='9rem'/></Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='pt-2'>
                                <h5 className='white-text'>Soporte</h5>
                                <p className='white-text'>Cuenta con la seguridad y confiabilidad constante que te brinda nuestros servicio de soporte 24/7.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm='4' className='text-center'>
                        <Row>
                            <Col >
                                <Button className='circle' variant='light'><BsColumnsGap style={{padding: '2rem'}} color="rgba(66,187,236,1)" size='9rem'/></Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='pt-2'>
                                <h5 className='white-text'>Infraestructura</h5>
                                <p className='white-text'>Enfocate con total tranquilidad en tus estudiantes y la calidad de tus contenidos educativos. Nosotros nos encargamos de las configuraciones, despliegues, balanceo de cargas etc.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm='4' className='text-center'>
                        <Row>
                            <Col >
                                <Button className='circle' variant='light'><BsTablet style={{padding: '2rem'}} color="rgba(66,187,236,1)" size='9rem'/></Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='pt-2'>
                                <h5 className='white-text'>Acceso offline</h5>
                                <p className='white-text'>Tus estudiantes no cuenta con conexión constante a internet?, desde la aplicación móvil podrán descargar y almacenar en sus dispositivos los contenidos educativos de sus clases.</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>     
            </Row>
            {show && offCanvas()}
        </div>
    )
};

export default Croom;