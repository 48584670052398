import React from 'react';
import './App.css';
import Header from './components/header';
import Routes from './Routes';
import Footer from './components/footer';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
    return (
        <div>
            <Header />
            <Routes />
            <Footer />
            {/* <Container className="mt-6 pt-6">
                <Routes />
            </Container> */}
        </div>
    );
};

export default App;