import React, { useEffect } from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap';
import { GiTie } from 'react-icons/gi';
import { MdAttachMoney, MdCheck, MdTimer } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import logo from '../img/LogoSolo.jpg'

const About = () => {

    const { pathname } = useLocation();

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[pathname]);
    
    return (
        <div className="mt-5">
            <Row className="third-section">
                <Container >
                    <Row className="mt-5">
                        <Col className="text-center">
                            <img 
                                src={logo}
                                alt="Logo GVIS"    
                            />
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col className="text-center">
                            <h1>Sobre nosotros</h1>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col className="text-center">
                            <h3>Somos una empresa nacida en Colombia, pero con un enfoque 
                                global. Actualmente contamos con oficinas en 
                                Colombia y Perú, tenemos presencia en Panamá, 
                                Ecuador y Chile. Desarrollamos proyectos que por medio
                                de la entrega de nuestros servicios nos permitan generar 
                                vínculos valiosos con personas, clientes, proveedores y 
                                demás entidades con quienes nos relacionemos.
                            </h3>
                            <h2 className="mt-3">¡Y queremos seguir haciéndolo!</h2>
                        </Col>
                    </Row>
                </Container>   
            </Row>
            <Container className="mt-5 mb-5">
                <Row className="justify-content-center">
                    <Col xs={'auto'} sm={6} md={4} className="text-center">
                        <Card className="pt-3 pb-3">
                            <Card.Body>
                                <Card.Title>
                                    Nuestra misión
                                </Card.Title>
                                <Card.Text>
                                    Aportar valor a nuestros clientes complementando sus capacidades
                                    operativas e institucionales, por medio de soluciones tecnológicas,
                                    capacitación, asesoría, infraestructura tecnológica y recurso
                                    humano.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={'auto'} sm={6} md={4} className="text-center">
                        <Card className="pt-3 pb-3">
                            <Card.Body>
                                <Card.Title>
                                    Nuestra visión
                                </Card.Title>
                                <Card.Text>
                                    Buscamos lograr la más alta satisfacción de nuestros clientes, 
                                    convirtiéndonos así en un valioso aliado estratégico, confiable 
                                    y permamente a largo plazo.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Row className='secondary-section'>
                <Row className="justify-content-center mb-5">
                    <Col className='text-center'>
                        <h2 className='white-text'>¿Cómo lo logramos?</h2>
                    </Col>
                </Row>
                <Container >
                    <Row className="justify-content-center">
                        <Col xs={'auto'} sm={6} md={4} className="text-center">
                            <Card className="pt-3 pb-3">
                                <Card.Body>
                                    <Card.Title>
                                        <MdAttachMoney color="rgba(66,187,236,1)" size='5rem' /><br></br>
                                        Con precios competitivos
                                    </Card.Title>
                                    <Card.Text>
                                        Ser un aliado estratégico demanda, entre otras cosas, contar 
                                        con precios competitivos en el mercado, y que estos se vean 
                                        reflejados aliviando el gasto y los presupuestos de nuestros 
                                        clientes.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={'auto'} sm={6} md={4} className="text-center">
                            <Card className="pt-3 pb-3">
                                <Card.Body>
                                    <Card.Title>
                                        <MdCheck color="rgba(66,187,236,1)" size='5rem' /><br></br>
                                        Con calidad
                                    </Card.Title>
                                    <Card.Text>
                                        Sabemos que en la búsqueda de la satisfacción de nuestros 
                                        clientes, un componente primordial es la calidad, por ello nos
                                        esforzamos constantemente en alcanzarla durante y después de la 
                                        entrega de nuestros servicios.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={'auto'} sm={6} md={4} className="text-center">
                            <Card className="pt-3 pb-3">
                                <Card.Body>
                                    <Card.Title>
                                        <MdTimer color="rgba(66,187,236,1)" size='5rem' /><br></br>
                                        Con cumplimiento
                                    </Card.Title>
                                    <Card.Text>
                                        Entendemos y valoramos la confianza depositada en nosotros por 
                                        parte de nuestros clientes, como consecuencia, nuestros 
                                        esfuerzos en el cumplimiento de compromisos y obligaciones 
                                        adquiridas con clientes, empleados y proveedores son 
                                        fundamentales y prioritarias.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={'auto'} sm={6} md={4} className="text-center">
                            <Card className="pt-3 pb-3">
                                <Card.Body>
                                    <Card.Title>
                                        <GiTie color="rgba(66,187,236,1)" size='5rem' /><br></br>
                                        Y con profesionalismo
                                    </Card.Title>
                                    <Card.Text>
                                        En Grupo Valor promovemos activamente nuestro profesionalismo.
                                        Todo lo que hacemos, lo hacemos profesionalmente, con personal 
                                        altamente calificado, y comprometido con nuestros objetivos y 
                                        valores institucionales.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                 </Container>
            </Row> 
        </div>
    )
};

export default About;