import React, { useState, useEffect } from "react";
import { Row, Col, Container, Card, Button } from "react-bootstrap";
import { SliderStatic } from "../components/slider";
import { IoIosVideocam, IoMdCheckmark } from "react-icons/io";
import { HiOutlineShoppingBag } from 'react-icons/hi';
import { BsGrid, BsMic, BsToggles, BsTv, BsAppIndicator } from "react-icons/bs";
import vroom from "../img/vroom.jpg";
import CanvasContactForm from "../components/canvasContactForm";
import { useLocation } from 'react-router-dom';

const sliderImgs = [
    {
        img: vroom,
        texto: {
            titulo: '¡Tu sistema de videoconferencia!',
            descripcion: '¡Conecta al instante con tus estudiantes o con tus colaboradores, sin descargas, sin instalaciones!',
        },
    },
];

const vroomInicial = [
    'Hasta 50 participantes simultáneos.',
    '5Gb de almacenamiento para tus grabaciones.',
    'Sin instalaciones, ni engorrosas configuraciones.',
    'Úsalo directamente desde tu navegador.',
    'Soporte técnico.',
];

const Vroom = () => {

    const [show, setShow] = useState(false);

    const offCanvas = () => {
        return (<CanvasContactForm onHide={setShow} placement='end' msg='Hola! busco más información sobre el servicio de VROOM. Por favor contactarme'/>)
    };

    const { pathname } = useLocation();

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[pathname]);

    return (
        <div className='mt-5'>
            <Row className='pt-4'>
                <Col>
                    <SliderStatic imgs={sliderImgs}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <hr className='gvis-separator'></hr>
                </Col>
            </Row>
            <Container >
                <Row className="justify-content-center">
                    <Col xs={'auto'} sm={6} md={6} className="text-center">
                        <Card >
                            <Card.Header>Vroom</Card.Header>
                            <Card.Body>
                                <Card.Title>Escencial</Card.Title>
                                <Card.Title><b><i>USD $ 15.00</i></b><br></br><i className="text-muted text-size-s">Por sala al mes</i></Card.Title>
                                <Button className="btn-gvis" onClick={()=>setShow(true)} variant="outline-primary" alt='¡Lo quiero!' size="block"><HiOutlineShoppingBag /></Button>
                                <Card.Text className="pt-3">
                                    Saca el máximo provecho a nuestro servicio vroom, donde contarás con salas de videoconferencia <a target='_blank' rel="noopener noreferrer" href="https://bigbluebutton.org/">BigBlueButton</a> desde nuestro 
                                    hosting profesional, y con nuestro excelente servicio de soporte técnico.
                                </Card.Text>
                                <Card.Text>
                                    {vroomInicial.map((element, index)=>(
                                        <span key={index}>
                                            <IoMdCheckmark /> {element}<br></br>
                                        </span>
                                    ))}
                                </Card.Text>
                                <Button className="btn-gvis" onClick={()=>setShow(true)} variant="outline-primary" alt='¡Lo quiero!' size="block"><HiOutlineShoppingBag /></Button>
                            </Card.Body>
                            <Card.Footer className="text-muted"><IoIosVideocam /></Card.Footer>
                        </Card>
                    </Col>
                    <Col xs={'auto'} sm={6} md={6} className="text-center">
                        <Card >
                            <Card.Header>Vroom</Card.Header>
                                <Card.Body>
                                    <Card.Title>Exclusivo</Card.Title>
                                    <Card.Text className="pt-3">
                                        Tu sistema de videconferencia basado en <a rel="noopener noreferrer" target='_blank' href="https://bigbluebutton.org/">BigBlueButton</a> desde nuestro 
                                        hosting profesional, y con nuestro excelente servicio de soporte técnico, para tu uso exclusivo.
                                        ¡Déjanos ponernos en contacto contigo y encontrar la configuración ideal para tu servicio vroom!
                                    </Card.Text>
                                    <Button onClick={()=>setShow(true)} className="mt-5 btn-block btn-gvis" variant='outline-primary'>Información de contacto</Button>
                                </Card.Body>
                            <Card.Footer className="text-muted"><IoIosVideocam /></Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Row className="pt-3 pb-2">
                <Col className="text-center">
                    <i className="text-size-m">* Estos valores no incluyen impuestos</i>
                </Col>
            </Row>
            <Row className='secondary-section'>
                <Row className='justify-content-center'>
                    <Col sm='4' className='text-center'>
                        <Row>
                            <Col>
                                <Button className='circle' variant='light'><BsTv style={{padding: '2rem'}} color="rgba(66,187,236,1)" size='9rem'/></Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='pt-2'>
                                <h5 className='white-text'>Whiteboard</h5>
                                <p className='white-text'>Carga presentaciones, haz anotaciones, toma una encuesta, comparte tu pantalla. Todo desde la pizarra interactiva.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm='4' className='text-center'>
                        <Row>
                            <Col>
                                <Button className='circle' variant='light'><BsToggles style={{padding: '2rem'}} color="rgba(66,187,236,1)" size='9rem'/></Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='pt-2'>
                                <h5 className='white-text'>Control total</h5>
                                <p className='white-text'>Modera eficazmente tus conferencias, otorga o quita permisos a los participantes, solo con un par de clics.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm='4' className='text-center'>
                        <Row>
                            <Col>
                                <Button className='circle' variant='light'><IoIosVideocam style={{padding: '2rem'}} color="rgba(66,187,236,1)" size='9rem'/></Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='pt-2'>
                                <h5 className='white-text'>Video en tiempo real</h5>
                                <p className='white-text'>Añade un toque humano a la conferencia, aprecia el lenguaje corporal y el nivel de atención de cada uno de los participantes.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm='4' className='text-center'>
                        <Row>
                            <Col>
                                <Button className='circle' variant='light'><BsAppIndicator style={{padding: '2rem'}} color="rgba(66,187,236,1)" size='9rem'/></Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='pt-2'>
                                <h5 className='white-text'>Grabaciones</h5>
                                <p className='white-text'>Graba tus sesiones de conferencia web y compártelas con los estudiantes, podrán verlas una y otra vez.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm='4' className='text-center'>
                        <Row>
                            <Col >
                                <Button className='circle' variant='light'><BsMic style={{padding: '2rem'}} color="rgba(66,187,236,1)" size='9rem'/></Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='pt-2'>
                                <h5 className='white-text'>Audio</h5>
                                <p className='white-text'>Los usuarios de Google Chrome y Mozilla Firefox, disfrutarán de audio de alta calidad y baja latencia gracias al WebRTC audio.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm='4' className='text-center'>
                        <Row>
                            <Col >
                                <Button className='circle' variant='light'><BsGrid style={{padding: '2rem'}} color="rgba(66,187,236,1)" size='9rem'/></Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='pt-2'>
                                <h5 className='white-text'>Sala de grupo</h5>
                                <p className='white-text'>Agrupa participantes en tu conferencia en grupos e incrementa la participación y colaboración.</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>     
            </Row>
            {show && offCanvas()}
        </div>
    );
};

export default Vroom;