import React, { useState , useEffect } from "react";
import { Row, Col, Button, Card, 
    Container } from "react-bootstrap";
import { HiOutlineShoppingBag } from 'react-icons/hi';
import { IoMdCheckmark, IoIosRocket } from 'react-icons/io';
import rocketMin1 from '../img/Mockup1.jpg';
import rocketMin2 from '../img/Mockup2.jpg';
import rocketMin3 from '../img/Mockup3.jpg';
import { SliderZoom } from '../components/slider';
import CanvasContactForm from "../components/canvasContactForm";
import { useLocation } from 'react-router-dom';

const sliderImgsViewPortMin = [
    {
        img: rocketMin1,
        texto: {
            titulo: '¿Qué es Rocket?',
            descripcion: 'Es una herramienta tecnológica que permite gestionar todos los procesos administrativos de una institución educativa.'
        },
    },
    {
        img: rocketMin2,
        texto: {
            titulo: '¡Toda una suite educativa!',
            // descripcion: 'Todo el poder de Moodle, en nuestra infraestructura profesional de alta disponibilidad',
            descripcion: 'Rocket se integra fácilmente con nuestras soluciones croom y vroom, extendiendo ampliamente sus capacidades.'        },
    },
    {
        img: rocketMin3,
        texto: {
            titulo: '¡Y mucho más!',
            descripcion: 'Conozca el estado de las cuentas por cobrar. Genere certificaciones y reportes académicos con un par de clics. Rocket se convertirá en la fuente de información de toda su institución.',
        },
    },
];

const rocketInicial = [
    "Administración académica.",
    "Admisiones, matrículas.",
    "Libreta de calificaciones",
    "Control de ingresos.",
    "Control de egresos.",
    "Reportes académicos.",
    "Reportes financieros.",
    "Certificaciones académicas."
];
const rocketCompleto = [
    "Aula virtual de aprendizaje.",
    "Sistema de videoconferencia.",
    "Sincronización de calificaciones.",
    "Alertas financieras.",
    "Soporte técnico.",
    "Soporte funcional."
];
const rocketExclusivo = [
    "Acceso desde de tu propio dominio institucional",
    "Personalización de look and feel de acuerdo a tu marca institucional",
];

const Rocket = () => {

    const [show, setShow] = useState(false);

    const offCanvas = () => {
        return (<CanvasContactForm onHide={setShow} placement='end' msg='Hola! busco más información sobre rocket. Por favor contactarme'/>)
    };

    const { pathname } = useLocation();

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[pathname]);

    return (
        <div className='mt-5'>
            <Row className='pt-4'>
                <Col>
                    <SliderZoom imgs={sliderImgsViewPortMin} height={450} showNavArrows={false}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <hr className='gvis-separator'></hr>
                </Col>
            </Row>
            <Container >
                <Row className="justify-content-center">
                    <Col xs={'auto'} sm={6} md={4} className="text-center">
                        <Card >
                            <Card.Header>Rocket</Card.Header>
                            <Card.Body>
                                <Card.Title>Escencial</Card.Title>
                                <Card.Title><b><i>Gratis</i></b><br></br><i className="text-muted text-size-s">Hasta 50 estudiantes, despues USD $0.99 por estudiante mes</i></Card.Title>
                                <Button className="btn-gvis" onClick={()=>setShow(true)} variant="outline-primary" alt='¡Lo quiero!' size="block"><HiOutlineShoppingBag /></Button>
                                <Card.Text className="pt-3">
                                    Administra fácilmente tu institución educativa, gestiona matrículas, cursos, inscripciones, profesores. Genera informes académicos rápidamente y controla la salud financiera de tu institución.
                                </Card.Text>
                                <Card.Text>
                                    {rocketInicial.map((element, index)=>(
                                        <span key={index}>
                                            <IoMdCheckmark /> {element}<br></br>
                                        </span>
                                    ))}
                                </Card.Text>
                                <Button className="btn-gvis" onClick={()=>setShow(true)} variant="outline-primary" alt='¡Lo quiero!' size="block"><HiOutlineShoppingBag /></Button>
                            </Card.Body>
                            <Card.Footer className="text-muted"><IoIosRocket /></Card.Footer>
                        </Card>
                    </Col>
                    <Col xs={'auto'} sm={6} md={4} className="text-center">
                        <Card >
                            <Card.Header>Rocket</Card.Header>
                            <Card.Body>
                                <Card.Title>Completo</Card.Title>
                                <Card.Title><b><i>USD $ 2.99</i></b><br></br><i className="text-muted text-size-s">Por estudiante al mes</i></Card.Title>
                                <Button className="btn-gvis" onClick={()=>setShow(true)} variant="outline-primary" alt='¡Lo quiero!' size="block"><HiOutlineShoppingBag /></Button>
                                <Card.Text className="pt-3">
                                    Además de administrar tu centro educativo; 
                                    Rocket estará integrado con nuestras soluciónes <i>Croom</i> y <i>Vroom</i>, 
                                    lo que te permitirá inscribir a tus estudiantes y profesores en aulas virtuales, desde donde podrán participar
                                    en actividades sincrónicas. Todo un ecosistema tecnológico apropiado para brindar educación 
                                    en línea, a distancia, presencial o semipresencial.
                                </Card.Text>
                                <Card.Text>
                                    {rocketInicial.map((element, index)=>(
                                        <span key={index}>
                                            <IoMdCheckmark /> {element}<br></br>
                                        </span>
                                    ))}
                                    {rocketCompleto.map((element, index)=>(
                                        <span key={index}>
                                            <IoMdCheckmark /> {element}<br></br>
                                        </span>
                                    ))}
                                </Card.Text>
                                <Button className="btn-gvis" onClick={()=>setShow(true)} variant="outline-primary" alt='¡Lo quiero!' size="block"><HiOutlineShoppingBag /></Button>
                            </Card.Body>
                            <Card.Footer className="text-muted"><IoIosRocket /></Card.Footer>
                        </Card>
                    </Col>
                    <Col xs={'auto'} sm={6} md={4} className="text-center">
                        <Card >
                            <Card.Header>Rocket</Card.Header>
                                <Card.Body>
                                    <Card.Title>Exclusivo</Card.Title>
                                    <Card.Title><b><i>USD $ 4.90</i></b><br></br><i className="text-muted text-size-s">Por estudiante al mes</i></Card.Title>
                                    <Button className="btn-gvis" onClick={()=>setShow(true)} variant="outline-primary" alt='¡Lo quiero!' size="block"><HiOutlineShoppingBag /></Button>
                                    <Card.Text className="pt-3">
                                        Contarás con todos los beneficios de <i>Rocket Completo </i> 
                                        en un ambiente 100% dedicado para tu institución educativa, 
                                        con personalización exclusiva alineada a tu marca institucional,
                                        y en una infraestructura profesional, de alta disponibilidad, 
                                        con un uptime mínimo de 99.99%.
                                    </Card.Text>
                                    <Card.Text>
                                        {rocketInicial.map((element, index)=>(
                                            <span key={index}>
                                                <IoMdCheckmark /> {element}<br></br>
                                            </span>
                                        ))}
                                        {rocketCompleto.map((element, index)=>(
                                            <span key={index}>
                                                <IoMdCheckmark /> {element}<br></br>
                                            </span>
                                        ))}
                                        {rocketExclusivo.map((element, index)=>(
                                            <span key={index}>
                                                <IoMdCheckmark /> {element}<br></br>
                                            </span>
                                        ))}
                                    </Card.Text>
                                    <Button className="btn-gvis" onClick={()=>setShow(true)} variant="outline-primary" alt='¡Lo quiero!' size="block"><HiOutlineShoppingBag /></Button>
                                </Card.Body>
                            <Card.Footer className="text-muted"><IoIosRocket /></Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Row className="pt-3 pb-2">
                <Col className="text-center">
                    <i className="text-size-m">* Estos valores no incluyen impuestos</i>
                </Col>
            </Row>
            <Row className='secondary-section pt-5 pb-5'>
                <Col className="text-center white-text">
                    <h2>¿Nuestro planes no se ajustan a tus necesidades?</h2>
                    <h3>¡Déjanos ponernos en contacto contigo y encontrar el plan ideal para ti!</h3>
                    <Button onClick={()=>setShow(true)} className="mt-5 btn-light2" variant='light'><h5 className="mt-2">Información de contacto</h5></Button>
                </Col>
            </Row>
            {show && offCanvas()}
        </div>
    )
};

export default Rocket;