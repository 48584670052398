import React, { useState } from 'react';
import { Navbar, Nav } from "react-bootstrap";
import logo from '../img/Logo-GVIS.jpg';
import { LinkContainer } from "react-router-bootstrap";
import { IoIosRocket, IoIosBrush, IoIosVideocam } from "react-icons/io";
import { FaGraduationCap } from "react-icons/fa";
import { GiCutDiamond } from 'react-icons/gi';
// import { HiOutlineShoppingBag } from 'react-icons/hi';

const Header = () => {

    const [isToggled, setIsToggled] = useState(false);

    // const showToolip = (props) => {
    //     //(bag[0].productId === "" ? bagLength.current = 0 : bagLength.current = bag.length);
    //     return (
    //         <Tooltip id="button-tooltip" {...props}>
    //             {'bagLength.current'}
    //         </Tooltip>
    //     );
    // };
    return (
        <header>
            <Navbar bg="light" expand="lg" fixed="top" className="bottom-shadow" onToggle={(expanded)=>{setIsToggled(expanded);}}>
                
                    <Navbar.Brand href="/">
                        <img 
                            src={logo}
                            alt="GVIS Logo"
                            className="header-logo"
                        />
                    </Navbar.Brand>
                
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse className="justify-content-end" id="main-navbar">
                        <Nav className="text-center">
                            <Nav.Item>
                                <LinkContainer to="/rocket">
                                    <Nav.Link href="/rocket"><IoIosRocket /> Rocket</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                            <Nav.Item>
                                <LinkContainer to="/croom">
                                    <Nav.Link href="/croom"><FaGraduationCap /> Croom</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                            <Nav.Item>
                                <LinkContainer to="/vroom">
                                    <Nav.Link href="/vroom"><IoIosVideocam /> Vroom</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                            <Nav.Item>
                                <LinkContainer to="/contenidos">
                                    <Nav.Link href="/contenidos"><IoIosBrush /> Contenidos</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                            <Nav.Item>
                                <LinkContainer to="/cristal">
                                    <Nav.Link href="/cristal"><GiCutDiamond /> Cristal</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                            <Nav.Item className={isToggled ? "ml-0" : 'ml-5'}>
                                {/* <OverlayTrigger
                                    placement="left"
                                    delay={{show:250, hide:4000}}
                                    overlay={showToolip}
                                    //show={toolTipShow}
                                >
                                    <Dropdown>
                                        <Dropdown.Toggle variant="link" id="dropdown-bag">
                                            <HiOutlineShoppingBag size={'18'}/>
                                        </Dropdown.Toggle>
                                        
                                        <Dropdown.Menu className="dropdown-menu-right">
                                            <LinkContainer to="/bag"><Dropdown.Item>Cesta</Dropdown.Item></LinkContainer>
                                            <Dropdown.Item>Cuenta</Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Item>LogIn/LogOut</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown> 
                                </OverlayTrigger> */}
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
            </Navbar>
        </header>
    )
};

export default Header;