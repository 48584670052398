import React from "react";
import { Row, Col } from "react-bootstrap";
import { FaFacebookSquare, FaLinkedin, FaWhatsapp} from "react-icons/fa";
import { LinkContainer } from 'react-router-bootstrap';
import { GoLocation } from 'react-icons/go';
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';

const Footer = () => {

    return (
        <div>
            <Row>
                <Col>
                    <hr className='gvis-separator'></hr>
                </Col>
            </Row>
            <Row>
                <Col className='ml-3'>
                    <LinkContainer to='/condiciones-de-uso' >
                        <a className="footer-text" href='condiciones-de-uso'>Términos y condiciones</a>
                    </LinkContainer> 
                    <span className='footer-text'> | </span>
                    <LinkContainer to='/politica-de-privacidad' >
                        <a className="footer-text" href='politica-de-privacidad'>Privacidad</a>
                    </LinkContainer>
                    <span className='footer-text'> | </span>
                    <LinkContainer to='/#' >
                        <a className="footer-text" href='/#'>Preferencias de cookies</a>
                    </LinkContainer>
                    <span className='footer-text'> | </span>
                    <LinkContainer to='/about' >
                        <a className="footer-text" href='about'>Sobre nosotros</a>
                    </LinkContainer>
                </Col>
                <Col className="text-right mr-3">
                    {/* <a rel="noopener noreferrer" href="https://www.facebook.com/gvalor.co" target="_blank"><FaFacebookSquare size={30}/></a> <FaInstagram size={30} /> <a rel="noopener noreferrer" href="https://www.linkedin.com/company/16192894/" target="_blank"><FaLinkedin size={30}/></a> <a rel="noopener noreferrer" href="https://wa.me/51922752025" target="_blank"><FaWhatsapp size={30} /></a> */}
                    <a rel="noopener noreferrer" href="https://www.facebook.com/gvalor.co" target="_blank"><FaFacebookSquare size={30}/></a><a rel="noopener noreferrer" href="https://www.linkedin.com/company/16192894/" target="_blank"><FaLinkedin size={30}/></a> <a rel="noopener noreferrer" href="https://wa.me/51922752025" target="_blank"><FaWhatsapp size={30} /></a>
                </Col>
            </Row>
            <Row>
                <Col>
                    <hr className='gvis-separator'></hr>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col className='ml-3 text-center'>
                    <span className="footer-text-sm">Copyright © Grupo Valor. Reservados todos los derechos.</span><br></br>
                    <span className="footer-text-sm"><GoLocation className="gvis-color"/> Carrera 69D 1 - 51 sur. Bogotá - Colombia. <AiOutlinePhone className="gvis-color"/>(+57)3108503798</span><br></br>
                    <span className="footer-text-sm"><GoLocation className="gvis-color"/> Calle islas aleutianas 120. La Molina. Lima - Perú. <AiOutlinePhone className="gvis-color"/>(+51)922752025</span><br></br>
                    <span className="footer-text-sm"><AiOutlineMail className="gvis-color"/> info@gvalor.co</span><br></br>
                </Col>
            </Row>
        </div>
    )
};

export default Footer;