import React, { useEffect } from 'react';
import { Row, Col, Container } from "react-bootstrap";
import '../App.css';
import { useLocation } from 'react-router-dom';

function PrivacePolitic() {

    const { pathname } = useLocation();

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[pathname]);

  return (
        <div className='mt-5'>
            <Container >
                <Row className='pt-4'>
                    <Col>
                        <h2>Política de privacidad</h2>
                        <p>Agradecemos tu interés en este sitio Web y páginas relacionadas. Tu privacidad es muy importante 
                            para nosotros y, a través de este Aviso de Privacidad (el “Aviso”) queremos que conozcas cómo 
                            y para qué utilizamos la información que recopilamos, por lo que te pedimos lo leas con atención.</p>
                        <p>Grupo Valor International Solutions SAS y Grupo educativo valor S.A.C. (las “Sociedades” o “GVIS”), 
                            están comprometidos con el respeto a tu privacidad y la Ley de Protección de Datos Personales. 
                            Toda información que nos brindes, considerada datos personales de acuerdo a la Ley, será debidamente 
                            tratada y conservada bajo nuestra responsabilidad en sistemas informáticos tanto en el Colombia, 
                            Perú y en el exterior, pudiendo ser también custodiada en sistemas informáticos ubicados en la nube.</p>
                        <p>El tratamiento y uso de tus datos personales por GVIS será realizado de conformidad con las 
                            condiciones y fines del presente Aviso, en la medida que has brindado tu consentimiento, 
                            ya sea por la suscripción de un contrato/formulario, inscripción en nuestra web, aceptación de 
                            términos mediante un clic u otro medio similar.</p>
                        <p>Si se requieren usar tus datos personales con fines diferentes a los señalados en el presente 
                            Aviso, te contactaremos a fin de recabar tu autorización.</p>
                        <h3>1. ¿Para qué fines recabamos y utilizamos tus datos personales?</h3>
                        <p>Para identificarte, localizarte, comunicarte, contactarte, enviarte información, dar cumplimiento a 
                            obligaciones que hemos contraído contigo, para elaborar bases de datos que serán utilizadas 
                            para ofrecerte productos y/o servicios de GVIS que pudieran ser de tu interés y/o de 
                            terceros que se promocionen o publiciten por tu intermedio, así como su transmisión a 
                            terceros por cualquier medio que permita la Ley y demás leyes que resulten aplicables.</p>
                        <p>El uso de los datos personales y datos sensibles tendrá relación directa con el tipo de interacción 
                            que tengas con GVIS, ya sea comercial, laboral, civil, mercantil o de cualquier otra naturaleza. 
                            La temporalidad del manejo de los datos personales y/o datos sensibles será indefinida a partir 
                            de la fecha en que nos proporcionaste éstos a GVIS.</p>
                        <h3>2. ¿Qué datos personales obtendremos y de dónde?</h3>
                        <p>Para las finalidades señaladas en este Aviso, podemos recabar tus datos personales de distintas 
                            formas: cuando nos los proporcionas directamente, cuando visitas nuestra web o utilizas 
                            nuestros servicios en línea y cuando obtenemos información a través de otras fuentes permitidas 
                            por la Ley.</p>
                        <p>Datos personales que recabamos de forma directa. Recabamos tus datos personales de 
                            forma directa cuando tú nos los proporcionas. Los datos que obtenemos por este medio 
                            pueden ser, entre otros:
                        </p>
                            <ul>
                                <li>Nombre</li>
                                <li>Domicilio</li>
                                <li>Teléfono</li>
                                <li>Edad</li>
                                <li>Correo electrónico</li>
                                <li>Educación</li>
                                <li>Sexo</li>
                                <li>Estado civil</li>
                            </ul>
                        <p>Datos personales que recabamos de forma indirecta. Aquellos que recabamos cuando visitas nuestra web 
                            o utilizas nuestros servicios en línea, entre otros:
                        </p>
                            <ul>
                                <li>Nombre</li>
                                <li>Domicilio</li>
                                <li>Teléfono</li>
                                <li>Edad</li>
                                <li>Correo electrónico</li>
                            </ul>
                        <p>Una vez que entregas a GVIS tus datos personales o sensibles, estos serán conservados en diferentes 
                            medios seguros que la tecnología permita, cuyo acceso estará limitado solamente a las 
                            personas físicas y/o jurídicas con las que GVIS tenga alguna relación. GVIS contará con 
                            las medidas de seguridad adecuadas para proteger el uso de tus datos personales y/o 
                            sensibles por parte de terceros no autorizados. En caso de requerimiento de alguna autoridad, 
                            tus datos personales y/o sensibles podrán ponerse a disposición de éstas, dentro del estricto 
                            cumplimiento a la Ley.</p>
                        <p>El tratamiento de tus datos personales, que has puesto a disposición de GVIS bajo cualquier 
                            forma o circunstancia, podrá ser efectuado de conformidad con los presentes términos y 
                            condiciones, por lo que desde este momento se entiende que autorizas expresamente a GVIS para 
                            tal efecto, hasta en tanto no manifiestes tu oposición mediante alguno de los medios que indica la Ley.</p>
                        <h3>3. ¿Cómo acceder o rectificar tus datos personales, o cancelar u oponerte a su uso?</h3>
                        <p>Tienes derecho a acceder a los datos personales que tenemos registrados y a los detalles del 
                            tratamiento de los mismos, así como a rectificarlos en caso de ser inexactos o incompletos; 
                            cancelarlos cuando consideres que no se requieren para alguna de las finalidades señaladas en 
                            el presente Aviso, estén siendo utilizados para finalidades no consentidas o haya finalizado la 
                            relación contractual o de servicio, o bien, oponerte al tratamiento de los mismos para fines 
                            específicos. Para hacerlo, deberás dirigir tu solicitud al correo electrónico: info@gvalor.co</p>
                        <h3>4. Datos personales sensibles</h3>
                        <p>Tratándose de datos personales sensibles, deberás proporcionar a GVIS tu consentimiento expreso 
                            y por escrito para su tratamiento a través de tu firma autógrafa, electrónica o cualquier 
                            mecanismo de autenticación que para tal efecto se establezca. De cualquier forma, nos comprometemos 
                            a no tratar estos datos para fines distintos al contrato o acuerdo específico para el que nos los 
                            proporcionaste, si no contamos con tu consentimiento expreso y por escrito.</p>
                        <h3>5. Modificaciones al aviso de privacidad</h3>
                        <p>Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente Aviso, 
                            para la atención de novedades legislativas, políticas internas o nuevos requerimientos para la prestación u 
                            ofrecimiento de nuestros servicios o productos.</p>
                        <p>Estas modificaciones estarán disponibles al público a través de esta páginas web.</p>
                    </Col>
                </Row>
            </Container>
        </div>
  );
}

export default PrivacePolitic;
