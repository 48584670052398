import React, { useRef, useState } from "react";
import { Offcanvas, Button, FloatingLabel, Form, Alert } from "react-bootstrap";

const CanvasContactForm = (props) => {

    const [show, setShow] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [nombre, setNombre] = useState('');
    const [email, setEmail] = useState('');
    const [telefono, setTelefono] = useState('');
    const [mensaje, setMensaje] = useState(props.msg ? props.msg : '');

    const variantAlert = useRef('info')
    const msgAlert = useRef('');

    const handleClose = () => {
        setShow(false);
        if(props.onHide){
            props.onHide(false);
        }
    };

    const enviarFormulario = () => {
        setShowAlert(false);
        //Conectar al API de envio
        //Hacer loading 
        variantAlert.current = 'success';
        msgAlert.current = 'Gracias! En breve nos pondremos en contacto contigo';
        //On error: variantAlert.current = 'danger'; msgAlert.current = 'Ups! Tenemos problemas para registrar tu información, por favor intentalo de nuevo';
        setShowAlert(true);
    };

    const enableEnvio = () => {
        return nombre.length > 3 &&
        email.includes('@') && telefono > 0 && 
        mensaje.length > 3
    };

    return (
        <Offcanvas 
            placement={props.placement ? props.placement : 'end'} 
            show={show} 
            onHide={handleClose}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Formulario de contacto</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <p>Gracias por interesarte en nuestros servicios, 
                por favor brindanos la siguiente información: </p>
                <FloatingLabel
                    controlId="nombreInput"
                    label="Tu nombre"
                    className="mb-3"
                >
                    <Form.Control 
                        type="text" 
                        placeholder="Tu nombre"
                        value={nombre}
                        onChange={e=>setNombre(e.target.value)}
                    />
                </FloatingLabel>
                <FloatingLabel
                    controlId="emailInput"
                    label="Correo electrónico"
                    className="mb-3"
                >
                    <Form.Control 
                        type="email" 
                        placeholder="Tu e-mail" 
                        value={email}
                        onChange={e=>setEmail(e.target.value)}
                    />
                </FloatingLabel>
                <FloatingLabel
                    controlId="telefonoInput"
                    label="Tu número telefónico de contacto"
                    className="mb-3"
                >
                    <Form.Control 
                        type="number" 
                        placeholder="Tu número telefónico de contacto"
                        value={telefono}
                        onChange={e=>setTelefono(e.target.value)}
                    />
                </FloatingLabel>
                <FloatingLabel 
                    controlId="comentariosTextarea" 
                    label="Mensaje"
                >
                    <Form.Control
                        as="textarea"
                        placeholder="Tus comentarios"
                        style={{ height: '7rem' }}
                        value={mensaje}
                        onChange={e=>setMensaje(e.target.value)}
                    />
                </FloatingLabel>
                <Button disabled={!enableEnvio()} onClick={()=>enviarFormulario()} variant='outline-primary' className='btn-block mt-3'>Enviar</Button>
                {showAlert && 
                    <Alert className="mt-3 text-center" variant={variantAlert.current}>
                        {msgAlert.current}
                    </Alert>
                }
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default CanvasContactForm;