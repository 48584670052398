import React from "react"
import { Route, Switch } from "react-router-dom";
import PrivacePolitic from "./containers/PrivacePolitic.js";
import UseTerms from "./containers/UseTerms.js";
import Home from "./containers/home";
import Croom from "./containers/croom.js";
import Rocket from "./containers/rocket.js";
import Vroom from "./containers/vroom.js";
import Contenidos from "./containers/contenidos.js";
import Cristal from './containers/cristal.js';
import About from './containers/about.js';

export default function Routes (){
    return (
        <Switch>
            <Route exact path="/">
                <Home />
            </Route>
            <Route exact path="/rocket">
                <Rocket />
            </Route>
            <Route exact path="/croom">
                <Croom />
            </Route>
            <Route exact path="/vroom">
                <Vroom />
            </Route>
            <Route exact path="/contenidos">
                <Contenidos />
            </Route>
            <Route exact path="/cristal">
                <Cristal />
            </Route>
            <Route exact path="/politica-de-privacidad">
                <PrivacePolitic />
            </Route>
            <Route exact path="/condiciones-de-uso">
                <UseTerms />
            </Route>
            <Route exact path="/about">
                <About />
            </Route>
        </Switch>
    );
}