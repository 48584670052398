import React, { useState, useEffect } from 'react';
import { Row, Col, Button, 
    Container } from "react-bootstrap";
import mk1 from "../img/mk1.jpg";
import mk2 from "../img/mk2.jpg";
import mk3 from "../img/mk3.jpg";
import CanvasContactForm from "../components/canvasContactForm";
import { SliderZoom } from '../components/slider';
import { FaBoxOpen, FaShoppingBag, FaMoneyBillAlt } from "react-icons/fa";
import { ImUsers } from "react-icons/im";
import { GoDashboard } from "react-icons/go";
import { useLocation } from 'react-router-dom';

const sliderImgs = [
    {
        img: mk1,
        texto: {
            titulo: '¿Qué es Cristal?',
            descripcion: 'Es una herramienta tecnológica, que te permite gestionar el inventario y los procesos de ventas y gastos de tu negocio o emprendimiento.'
        },
    },
    {
        img: mk2,
        texto: {
            titulo: 'Gestión de clientes',
            // descripcion: 'Todo el poder de Moodle, en nuestra infraestructura profesional de alta disponibilidad',
            descripcion: 'Almacena la información de tus clientes en un único lugar, de una manera organizada y centralizada, consulta en cualquier momento y actualiza cuando lo necesites.'},
    },
    {
        img: mk3,
        texto: {
            titulo: 'Control y seguimiento',
            descripcion: 'Consuta en los distintos tableros de control información prioritaria para tu negocio, como: el estado del inventario, las ventas registradas en el día, o fechas importantes para tus clientes.',
        },
    },
];

const Cristal = () => {

    const [show, setShow] = useState(false);

    const offCanvas = () => {
        return (<CanvasContactForm onHide={setShow} placement='end' msg='Hola! quiero recibir acceso a cristal. Por favor contactarme'/>)
    };

    const { pathname } = useLocation();

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[pathname]);

    return (
        <div className='mt-5'>
            <Row>
                <Col>
                    <SliderZoom imgs={sliderImgs} height={450} showNavArrows={false}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <hr className='gvis-separator'></hr>
                </Col>
            </Row>
            <Row className="third-section">
                <Container >
                    <Row className="mt-5">
                        <Col className="text-center">
                            <h1>Cristal es el sistema de gestión para tu negocio</h1>
                        </Col>
                    </Row>
                    <Row className="mb-5">
                        <Col className="text-center">
                            <h3>Con cristal podrás tener un mejor control de tu negocio o emprendimiento, 
                                todo de una manera centralizada y disponible desde cualquier lugar, 
                                solo necesitas una conexión a internet.</h3>
                        </Col>
                    </Row>
                </Container>
            </Row>
            <Row className='secondary-section'>
                <Row className="justify-content-center">
                    <Col sm='4' className='text-center'>
                        <Row>
                            <Col>
                                <Button className='circle' variant='light'><FaBoxOpen style={{padding: '2rem'}} color="rgba(66,187,236,1)" size='9rem'/></Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='pt-2'>
                                <h5 className='white-text'>Inventario</h5>
                                <p className='white-text'>Controla el estado del inventario de tu negocio, crea o adiciona nuevos activos así como el estado y cantidad disponible en el inventario.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm='4' className='text-center'>
                        <Row>
                            <Col>
                                <Button className='circle' variant='light'><FaShoppingBag style={{padding: '2rem'}} color="rgba(66,187,236,1)" size='9rem'/></Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='pt-2'>
                                <h5 className='white-text'>Ventas</h5>
                                <p className='white-text'>Registra las ventas de tu negocio, los productos vendidos se actualizarán instantaneamente en el inventario disponible.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm='4' className='text-center'>
                        <Row>
                            <Col>
                                <Button className='circle' variant='light'><FaMoneyBillAlt style={{padding: '2rem'}} color="rgba(66,187,236,1)" size='9rem'/></Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='pt-2'>
                                <h5 className='white-text'>Egresos</h5>
                                <p className='white-text'>Controla los egresos y gastos de tu negocio, conoce la proporción de egresos respecto a los ingresos y haz un mejor uso del dinero que genera tu negocio.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm='4' className='text-center'>
                        <Row>
                            <Col>
                                <Button className='circle' variant='light'><ImUsers style={{padding: '2rem'}} color="rgba(66,187,236,1)" size='9rem'/></Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='pt-2'>
                                <h5 className='white-text'>Clientes</h5>
                                <p className='white-text'>Conoce, registra, manten y actualiza toda la información de tus clientes, podrás realizar trazabilidad a las compras realizadas por ellos.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm='4' className='text-center'>
                        <Row>
                            <Col>
                                <Button className='circle' variant='light'><GoDashboard style={{padding: '2rem'}} color="rgba(66,187,236,1)" size='9rem'/></Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='pt-2'>
                                <h5 className='white-text'>Reportes</h5>
                                <p className='white-text'>Genera informes que te permitan tener una imagen del estado de tu negocio, movimiento del inventario, ingresos vs egresos, mejores clientes y mucho más.</p>
                                <p style={{fontStyle: 'italic'}} className="white-text">
                                    *Próximamente
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Row>
            <Container >
                <Row className="mt-5">
                    <Col className="text-center">
                        <h1>Accede a la prueba gratuita</h1>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col className="text-center">
                        <h3>Cristal se encuentra en fase beta y en continuo desarrollo ¡solicita un acceso preliminar a cristal y empieza a aprovechar todas sus ventajas!.</h3>
                        <Button onClick={()=>setShow(true)} className="mt-5 btn-gvis" variant='outline-primary'><h5 className="mt-2">Solicitar acceso gratuito</h5></Button>
                    </Col>
                </Row>
            </Container>
            {show && offCanvas()}
        </div>
    );
};

export default Cristal;